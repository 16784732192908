import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Outlet } from 'react-router-dom'
import { RootState } from '../redux/configureStore'

export default function RootRoute() {
  const navigate = useNavigate()
  const { username } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (!username) {
      navigate('/login')
    } else {
      navigate('/dashboard')
    }
  }, [navigate, username])

  return <Outlet />
}
