/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionItems } from '../types/global'
import { useLazyGetAreasQuery } from '../redux/areaApi'
import { useLazyGetLocationsQuery } from '../redux/locationApi'
import useLocaleName from './useLocaleName'
import { AreaQueryParams } from '../types/area'
import { LocationQueryParams } from '../types/location'

export default function useLocationsFilter(type: 'code' | 'id' = 'code') {
  const { i18n } = useTranslation()
  const language = i18n.language
  const getName = useLocaleName()
  const [areas, setAreas] = useState<OptionItems>([])
  const [locations, setLocations] = useState<OptionItems>([])
  const currentOrganization = useRef<string>('')

  const [getAreas, { data: areasData, isSuccess: successAreas }] =
    useLazyGetAreasQuery()

  const [getLocations, { data: locationsData, isSuccess: successLocations }] =
    useLazyGetLocationsQuery()

  useEffect(() => {
    if (successAreas && areasData) {
      // Set the areas group in locations filter
      const list = areasData.map((item) => {
        return {
          value: item.id,
          label: getName(item.areaName),
        }
      })
      setAreas(list)
    }
  }, [successAreas, areasData, language])

  useEffect(() => {
    if (successLocations && locationsData) {
      // Set the locations list in the locations filter
      const list = locationsData.map((item) => {
        return {
          value: type === 'code' ? item.locationCode : item.id,
          label: `${getName(item.locationName)} - (${item.locationCode})`,
          group: item.areaId,
        }
      })
      setLocations(list)
    }
  }, [successLocations, locationsData, language])

  // Reset the areas and locations filter
  const resetLocationsFilter = () => {
    setAreas([])
    setLocations([])
  }

  // Trigger to get the areas and locations list from backend api
  const getLocationsFilter = (organization?: string) => {
    if (
      currentOrganization.current !== '' &&
      currentOrganization.current !== organization
    ) {
      resetLocationsFilter()
    }

    currentOrganization.current = organization || ''

    const areaQuery: AreaQueryParams = {}
    const locationQuery: LocationQueryParams = {}

    // If organization is provided, filter the areas and locations by organization
    if (organization) {
      areaQuery.organization = organization
      locationQuery.organization = organization
    }

    getAreas(areaQuery)
    getLocations(locationQuery)
  }

  return { areas, locations, resetLocationsFilter, getLocationsFilter }
}
