import { openDB } from 'idb'

// Increment the badge count for PWA
const incrementBadge = async (navigator: Navigator) => {
  const db = await openDB('alerts', 1, {
    upgrade(db) {
      db.createObjectStore('badge')
    },
  })

  let count = (await db.get('badge', 'count')) || 0
  count += 1

  await db.put('badge', count, 'count')

  if (navigator.setAppBadge) {
    navigator.setAppBadge(count)
  }
}

// Clear the badge count for PWA
const clearBadge = async (navigator: Navigator) => {
  const db = await openDB('alerts', 1, {
    upgrade(db) {
      db.createObjectStore('badge')
    },
  })

  await db.put('badge', 0, 'count')

  if (navigator.clearAppBadge) {
    navigator.clearAppBadge()
  }
}

export { incrementBadge, clearBadge }
