import { createTheme, ThemeOptions } from '@mui/material'

export const colors = {
  black: '#1B3643',
  white: '#FFFFFF',
  primary: '#249EBD',
  secondary: '#25C794',
  lightText: '#FFFFFF',
  lightBackground: '#F3F3F3',
  error: '#E84032',
  lightError: '#FFB0B0',
  darkError: '#9E0000',
  warning: '#E3AB20',
  success: '#16A661',
  info: '#616161',
  disabled: '#EEEEEE',
  grey: '#B9B9B9',
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.lightText,
    },
    secondary: {
      main: colors.secondary,
      light: colors.grey,
      contrastText: colors.lightText,
    },
    success: {
      main: colors.success,
      contrastText: colors.lightText,
    },
    info: {
      main: colors.info,
      contrastText: colors.lightText,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.lightText,
    },
    error: {
      main: colors.error,
      dark: colors.darkError,
      light: colors.lightError,
      contrastText: colors.lightText,
    },
    background: {
      paper: colors.white,
      default: colors.lightBackground,
    },
    common: {
      black: colors.black,
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: '700',
      color: colors.black,
      marginBottom: '1rem',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: '700',
      color: colors.black,
      marginBottom: '1rem',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '700',
      color: colors.black,
      marginBottom: '1rem',
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: '700',
      color: colors.black,
      marginBottom: '1rem',
    },
    h5: {
      fontSize: '1.1rem',
      color: colors.black,
    },
    fontFamily:
      '"Noto Sans", "PingFang TC" ,"Microsoft JhengHei", "Microsoft YaHei", "Arial", sans-serif',
  },
  shadows: [
    ...createTheme({}).shadows.slice(0, 3),
    Array(4).fill('rgba(60, 50, 65, 0.1) 0px 2px 10px 0px'),
    ...createTheme({}).shadows.slice(4),
  ] as ThemeOptions['shadows'],
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: '0px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor:
            ownerState.disabled === true ? colors.disabled : colors.white,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor:
            ownerState.disabled === true ? colors.disabled : colors.white,
        }),
      },
    },
  },
})

export default theme
