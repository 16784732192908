import React from 'react'
import { CircularProgress } from '@mui/material'
import CenterBox from '../styles/CenterBox.styled'

export default function Loading({ height }: { height?: string }) {
  return (
    <CenterBox sx={{ height }}>
      <CircularProgress />
    </CenterBox>
  )
}
