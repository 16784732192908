import React, { useEffect, useRef } from 'react'
import Loading from '../components/Loading'
import { usePostLogoutMutation } from '../redux/authApi'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../redux/configureStore'
import { userLogout, userCookieExpired } from '../redux/userSlice'
import { alertApi } from '../redux/alertApi'
import { areaApi } from '../redux/areaApi'
import { locationApi } from '../redux/locationApi'
import { organizationApi } from '../redux/organizationApi'
import { userApi } from '../redux/userApi'

export default function Logout() {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [postLogout] = usePostLogoutMutation()
  const hasLoggedOut = useRef(false)

  const logout = async () => {
    // Avoid logout twice in React strict mode
    if (hasLoggedOut.current) {
      return
    }

    hasLoggedOut.current = true

    await postLogout()
      .unwrap()
      .catch(() => {
        dispatch(userCookieExpired(true))
      })

    dispatch(userLogout())
    dispatch(alertApi.util.resetApiState())
    dispatch(areaApi.util.resetApiState())
    dispatch(locationApi.util.resetApiState())
    dispatch(organizationApi.util.resetApiState())
    dispatch(userApi.util.resetApiState())
    navigate('/login')
  }

  useEffect(() => {
    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loading height="100vh" />
}
