import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import {
  LocationsList,
  LocationPostItem,
  LocationPatchItem,
  LocationDetailsItem,
  LocationQueryParams,
} from '../types/location'
import {
  CreateResetSignalItem,
  SignalTokenBody,
  ResetSignalResponse,
} from '../types/signal'

export const locationApi = createApi({
  baseQuery,
  reducerPath: 'locationApi',
  endpoints: (build) => ({
    getLocations: build.query<LocationsList, LocationQueryParams>({
      query: (params) => ({
        url: '/locations',
        method: 'GET',
        params,
      }),
    }),
    getLocationDetails: build.query<LocationDetailsItem, string>({
      query: (id) => ({
        url: `/locations/${id}`,
        method: 'GET',
      }),
    }),
    postLocation: build.mutation<LocationDetailsItem, LocationPostItem>({
      query: (body) => ({
        url: '/locations',
        method: 'POST',
        body,
      }),
    }),
    patchLocation: build.mutation<LocationDetailsItem, LocationPatchItem>({
      query: (data) => {
        const { id, ...body } = data
        return {
          url: `/locations/${id}`,
          method: 'PATCH',
          body,
        }
      },
    }),
    deleteLocation: build.mutation<void, string>({
      query: (id) => ({
        url: `/locations/${id}`,
        method: 'DELETE',
      }),
    }),
    createResetSignal: build.mutation<SignalTokenBody, CreateResetSignalItem>({
      query: (body) => ({
        url: '/signal/create-reset',
        method: 'POST',
        body,
      }),
    }),
    resetSignal: build.mutation<ResetSignalResponse, SignalTokenBody>({
      query: (body) => ({
        url: '/signal/reset',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useLazyGetLocationDetailsQuery,
  usePostLocationMutation,
  usePatchLocationMutation,
  useDeleteLocationMutation,
  useCreateResetSignalMutation,
  useResetSignalMutation,
} = locationApi
