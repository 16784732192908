/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { format } from 'date-fns'
import { Typography, Box, FormControlLabel, Switch } from '@mui/material'
import { useSelector } from 'react-redux'

import { usePostNfcVerifyMutation } from '../redux/ntagApi'
import Modal from '../components/Modal'
import PageTitle from '../components/PageTitle'
import useErrorMessage from '../hooks/useErrorMessage'
import RoundButton from '../styles/RoundButton.styled'
import CenterBox from '../styles/CenterBox.styled'

import type { NtagVerifyRequest } from '../types/ntag'
import type { RootState } from '../redux/configureStore'

export default function NfcReader() {
  const { t } = useTranslation()
  const ndef = useRef<NDEFReader | null>(null)
  const [scanning, setScanning] = useState<boolean>(false)
  const [isVerified, setIsVerified] = useState<boolean | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [testMode, setTestMode] = useState<boolean>(false)

  const { userRole } = useSelector((state: RootState) => state.user)
  const errMessage = useErrorMessage()

  const [
    postNfcVerify,
    { data: nfcVerifyResult, isSuccess, isError, error, reset: resetNfcVerify },
  ] = usePostNfcVerifyMutation()

  const stopAndOpen = () => {
    stopScanNfc()
    setIsOpen(true)
  }

  const handleReadingError = () => {
    setIsVerified(false)
    setErrorMessage('ERROR_NFC_READING')
    stopAndOpen()
  }

  // Stop scanning NFC
  const stopScanNfc = async () => {
    if (!ndef.current) {
      return
    }
    ndef.current.removeEventListener('readingerror', handleReadingError)
    ndef.current.removeEventListener('reading', handleReading)
    ndef.current = null
    setScanning(false)
  }

  const handleReading = async ({ message }: any) => {
    let verified = false
    const verifyNtag = async () => {
      const textDecoder = new TextDecoder('utf-8')
      const records = message.records[0]?.data
      if (!records) {
        return
      }
      const decodedData = textDecoder.decode(records)
      if (!decodedData) {
        return
      }
      const { org, picc, cmac } = Object.fromEntries(
        new URLSearchParams(decodedData),
      )
      if (!org || !picc || !cmac) {
        return
      }

      try {
        const ntagBody: NtagVerifyRequest = {
          org,
          picc,
          cmac,
        }

        if (testMode) {
          ntagBody.test = true
        }

        const ntagVerify = await postNfcVerify(ntagBody).unwrap()

        verified = ntagVerify.verified
      } catch (err: any) {
        setErrorMessage(err.message)
      }
    }
    await verifyNtag()
    setIsVerified(verified)
    stopAndOpen()
  }

  const scanNfc = async () => {
    try {
      setScanning(true)
      setIsVerified(null)
      resetNfcVerify()
      if (!ndef.current) {
        ndef.current = new NDEFReader()
      }
      await ndef.current.scan()
      ndef.current.addEventListener('readingerror', handleReadingError)
      ndef.current.addEventListener('reading', handleReading)
    } catch (err: any) {
      setIsVerified(false)
      stopAndOpen()
      if (err.name === 'NotAllowedError') {
        setErrorMessage('ERROR_NFC_PERMISSION')
        return
      }

      if (err.message === 'NDEFReader is not defined') {
        setErrorMessage('ERROR_NFC_NOT_SUPPORTED')
        return
      }
      setErrorMessage('ERROR_NFC_UNKNOWN')
    }
  }

  useEffect(() => {
    if (isSuccess && nfcVerifyResult) {
      setIsVerified(nfcVerifyResult.verified)
    }
  }, [nfcVerifyResult, isSuccess])

  useEffect(() => {
    if (userRole === 'system') {
      setTestMode(true)
    }
  }, [])

  return (
    <>
      <PageTitle title={t('NFC_READER')} />
      <CenterBox sx={{ flexDirection: 'column' }}>
        <FormControlLabel
          label={t('NFC_TEST_MODE')}
          sx={{ mb: 2 }}
          control={
            <Switch
              checked={testMode}
              onChange={() => setTestMode(!testMode)}
            />
          }
        />
        <RoundButton
          id="scanButton"
          onClick={scanning ? stopScanNfc : scanNfc}
          variant="contained"
          color={scanning ? 'error' : 'primary'}
        >
          {t(scanning ? 'NFC_CANCEL_SCAN' : 'NFC_START_TO_SCAN')}
        </RoundButton>
        <Box mt={2} sx={{ textAlign: 'center' }}>
          <Typography variant="h3">
            {t(scanning ? 'NFC_READY_TO_SCAN' : 'NGC_PRESS_BUTTON_TO_SCAN')}
          </Typography>
          {scanning && (
            <Typography variant="body1">
              {t('NFC_SCAN_INSTRUCTIONS')}
            </Typography>
          )}
        </Box>
      </CenterBox>
      <Modal
        isOpen={isOpen}
        size="lg"
        content={t('NFC_READER_NOTIFICATION')}
        onClose={() => setIsOpen(false)}
        onlyClose={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mt: 2,
          }}
        >
          {isSuccess && nfcVerifyResult && isVerified && (
            <>
              <CheckCircleOutlineRoundedIcon color="success" fontSize="large" />
              <Typography variant="h4">{t('NFC_READER_SUCCESS')}</Typography>
              {nfcVerifyResult.timestamp && (
                <>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    {t('NFC_VERIFIED_AT')}
                    {format(
                      new Date(nfcVerifyResult.timestamp),
                      'yyyy-MM-dd HH:mm:ss',
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight:
                        nfcVerifyResult.resetSignals === -1 ? '700' : '400',
                    }}
                  >
                    {nfcVerifyResult.resetSignals > 0
                      ? t('NFC_RESET_SIGNALS', {
                          count: nfcVerifyResult.resetSignals,
                        })
                      : nfcVerifyResult.resetSignals === 0
                        ? t('NFC_NO_RESET_SIGNALS')
                        : t('NFC_TEST_MODE_SUCCESS')}
                  </Typography>
                </>
              )}
            </>
          )}
          {isVerified === false && (
            <>
              <CancelRoundedIcon color="error" fontSize="large" />
              <Typography variant="h4">{t('NFC_READER_ERROR')}</Typography>
              {errorMessage && (
                <Typography variant="body1">{errorMessage}</Typography>
              )}
              {isError && error && (
                <Typography variant="body1">
                  {errMessage('ntagVerify', '', error)}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Modal>
    </>
  )
}
