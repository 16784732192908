import React from 'react'
import { Outlet } from 'react-router-dom'
import useUserDetails from '../hooks/useUserDetails'
import NoResults from '../components/NoResults'

export default function UserRoleForbidden({ userRole }: { userRole: string }) {
  const { isSystemAdmin, isAdmin } = useUserDetails()
  return (
    <>
      {(userRole === 'system' && !isSystemAdmin) ||
      (userRole === 'admin' && !isAdmin) ? (
        <NoResults forbidden={true} />
      ) : (
        <Outlet />
      )}
    </>
  )
}
