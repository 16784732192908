import React, { useEffect, useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import { Container, Box, IconButton, Drawer, Tooltip } from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { MobileView } from 'react-device-detect'
import { topMenu } from '../components/MenuItems'
import LanguageSwitcher from '../components/LanguageSwitcher'
import Modal from '../components/Modal'
import DrawerList from '../components/DrawerList'
import VerticalCenterBox from '../styles/VerticalCenterBox.styled'
import { useNavigate } from 'react-router-dom'
import { RootState, AppDispatch } from '../redux/configureStore'
import { setOpenDrawer } from '../redux/userSlice'
import { setFetchError } from '../redux/generalSlice'

const drawerWidth = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const FixedDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiList-padding': {
    paddingRight: theme.spacing(1),
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function Layout() {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { username, openDrawer } = useSelector((state: RootState) => state.user)
  const { fetchError } = useSelector((state: RootState) => state.general)
  const [isUser, setIsUser] = useState(false)

  useEffect(() => {
    if (!username) {
      navigate('/login')
    } else {
      setIsUser(true)
    }
  }, [username, navigate])

  const handleDrawer = () => {
    dispatch(setOpenDrawer(!openDrawer))
  }

  const closeDrawer = () => {
    dispatch(setOpenDrawer(false))
  }

  const handleMenuItem = (path: string) => {
    navigate(path)
  }

  if (isUser) {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <FixedDrawer
              variant="permanent"
              open={openDrawer}
              PaperProps={{
                sx: {
                  backgroundColor: theme.palette.background.default,
                  border: 'none',
                },
              }}
            >
              <DrawerList />
            </FixedDrawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, overflowY: 'scroll', height: '100vh', pb: 3 }}
          >
            <Container maxWidth="xl">
              <VerticalCenterBox
                component="header"
                my={2}
                sx={{
                  justifyContent: 'space-between',
                }}
              >
                <Tooltip title={t('MENU')}>
                  <IconButton color="info" onClick={handleDrawer}>
                    <MenuRoundedIcon />
                  </IconButton>
                </Tooltip>
                <VerticalCenterBox sx={{ gap: 1 }}>
                  <LanguageSwitcher />
                  <Tooltip title={t('REFRESH')}>
                    <IconButton
                      color="info"
                      onClick={() => window.location.reload()}
                    >
                      <RefreshRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  {topMenu.map((item, index) => {
                    if (item.isMobile) {
                      return (
                        <MobileView key={index}>
                          <Tooltip title={t(item.name)}>
                            <IconButton
                              color="info"
                              onClick={() => handleMenuItem(item.path)}
                            >
                              {item.icon}
                            </IconButton>
                          </Tooltip>
                        </MobileView>
                      )
                    }
                    return (
                      <Tooltip title={t(item.name)} key={index}>
                        <IconButton
                          color="info"
                          onClick={() => handleMenuItem(item.path)}
                        >
                          {item.icon}
                        </IconButton>
                      </Tooltip>
                    )
                  })}
                </VerticalCenterBox>
              </VerticalCenterBox>
              <Outlet />
            </Container>
          </Box>
        </Box>
        <Drawer
          anchor="left"
          open={openDrawer}
          onClose={closeDrawer}
          variant="temporary"
          sx={{ display: { xs: 'block', md: 'none' } }}
          PaperProps={{
            sx: { width: '100%' },
          }}
        >
          <DrawerList mobile={true} />
        </Drawer>
        <Modal
          isOpen={fetchError}
          onClose={() => dispatch(setFetchError(false))}
          content={t('ERROR_SERVER_CONNECTION')}
          onlyClose={true}
        />
      </>
    )
  } else {
    return <></>
  }
}
