/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Pagination, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useBlocker } from 'react-router-dom'
import * as yup from 'yup'
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import {
  useLazyGetAlertsQuery,
  usePatchAlertsMutation,
} from '../redux/alertApi'
import { useLazyGetOrganizationsQuery } from '../redux/organizationApi'
import {
  AlertQueryForm,
  AlertQueryParams,
  AlertItems,
  AlertPatchForm,
  AlertPatchItems,
} from '../types/alert'
import AlertCard from '../components/AlertCard'
import NoResults from '../components/NoResults'
import Modal from '../components/Modal'
import {
  FormDatePicker,
  FormAutoComplete,
  FormPullDown,
} from '../components/FormController'
import SearchFilter from '../components/SearchFilter'
import Loading from '../components/Loading'
import PageTitle from '../components/PageTitle'
import useUserDetails from '../hooks/useUserDetails'
import useLocaleName from '../hooks/useLocaleName'
import useErrorMessage from '../hooks/useErrorMessage'
import useLocationsFilter from '../hooks/useLocationsFilter'
import CenterBox from '../styles/CenterBox.styled'
import RoundButton from '../styles/RoundButton.styled'
import AlertMessage from '../styles/AlertMessage.styled'
import { actionList } from '../utils/global'
import { clearBadge } from '../utils/badge'

const pageSize = 8

export default function Alerts() {
  const { t } = useTranslation()
  const { isSystemAdmin } = useUserDetails()
  const getName = useLocaleName()
  const errorMessage = useErrorMessage()

  const currentFilter = useRef<AlertQueryParams | null>(null)
  const currentResult = useRef<AlertItems | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const actionListTranslated = actionList.map((item) => ({
    value: item.value,
    label: t(item.label),
  }))

  const [triggerOrganizations, { data: organizationData }] =
    useLazyGetOrganizationsQuery()

  const organizationSearchItems = organizationData?.map((item) => ({
    value: item.organizationCode,
    label: getName(item.organizationName),
  }))

  const [getAlert, { data, isFetching, isSuccess }] = useLazyGetAlertsQuery()

  // Edit area mutation
  const [
    patchAlerts,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = usePatchAlertsMutation()

  const patchItems = (result: AlertItems) => {
    return result.items.map((item) => {
      return { id: item.id, action: item.action, comment: item.comment }
    })
  }

  // Compare the form data with the current data
  const compare = (form: AlertPatchItems, currentRef: AlertItems) => {
    if (form) {
      const result = []
      const current = patchItems(currentRef)
      for (let i = 0; i < form.length; i++) {
        if (
          form[i].id !== current[i].id ||
          form[i].action !== current[i].action ||
          form[i].comment !== current[i].comment
        ) {
          result.push(form[i])
        }
      }
      return result
    } else {
      return []
    }
  }

  // Check if there are changes in the form data
  const noDataChange = () => {
    const watchPatch = watch('items')
    let allowSubmit = true
    if (currentResult.current && watchPatch) {
      const result = compare(watchPatch, currentResult.current)
      if (result.length !== 0) {
        allowSubmit = false
      }
    }
    return allowSubmit
  }

  // Schema of the search filter form
  const searchSchema = yup.object().shape({
    organization: yup.string().when('$isSystemAdmin', {
      is: () => isSystemAdmin,
      then: (schema) =>
        schema.required(
          t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION') }),
        ),
      otherwise: (schema) => schema.optional(),
    }),
    location: yup.string().optional().nullable(),
    action: yup.array().of(yup.string().optional()).optional(),
    start: yup.date().optional().nullable(),
    end: yup.date().optional().nullable(),
  })

  // Search filter React hook form setup
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    formState: { errors: errorsSearch },
    reset: resetSearch,
    setValue: setValueSearch,
    watch: watchSearch,
  } = useForm<AlertQueryForm>({
    defaultValues: {
      organization: '',
      action: [],
      start: undefined,
      end: undefined,
      location: '',
    },
    resolver: yupResolver(searchSchema), // use yup to validate form values
  })

  // Form submit search data handler
  const onSubmitSearch: SubmitHandler<AlertQueryForm> = (submitData) => {
    resetEdit()
    const filter = { ...submitData, pageSize }

    // Remove the organization filter if the user is not a system admin
    if (!isSystemAdmin && submitData.organization === '') {
      filter.organization = undefined
    }

    if (submitData.location === '' || !submitData.location === null) {
      delete filter.location
    }

    const getData = {
      ...filter,
      location: filter.location ? filter.location : undefined,
      start: filter.start
        ? dayjs(filter.start).format('YYYY-MM-DD')
        : undefined,
      end: filter.end ? dayjs(filter.end).format('YYYY-MM-DD') : undefined,
    }

    const allowSubmit = noDataChange()
    if (allowSubmit) {
      getAlert(getData)
        .unwrap()
        .then((result) => {
          currentFilter.current = getData
          currentResult.current = result
          setValue('items', patchItems(result))
        })
        .catch(() => {})
    } else {
      setIsOpen(true)
    }
  }

  // Handle page change
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    resetEdit()
    const allowSubmit = noDataChange()
    if (allowSubmit) {
      getAlert({ ...currentFilter.current, page: value, pageSize })
        .unwrap()
        .then((result) => {
          currentResult.current = result
          setValue('items', patchItems(result))
        })
        .catch(() => {})
    } else {
      setIsOpen(true)
    }
  }

  // Schema of the create and edit form
  const schema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        action: yup.string().optional(),
        comment: yup.string().optional(),
      }),
    ),
  })

  // React hook form setup
  const { handleSubmit, control, setValue, watch } = useForm<AlertPatchForm>({
    resolver: yupResolver(schema), // use yup to validate form values
  })

  const { fields } = useFieldArray({
    control,
    name: 'items',
  })

  // Form submit data handler
  const onSubmit: SubmitHandler<AlertPatchForm> = async (submitData) => {
    resetEdit()
    if (currentResult.current && submitData.items) {
      const patchData = compare(submitData.items, currentResult.current)
      if (patchData.length > 0) {
        await patchAlerts(patchData)
      }
    }
  }

  // Reset the form to the current data
  const handleReset = () => {
    if (currentResult.current) {
      setValue('items', patchItems(currentResult.current))
    }
    setIsOpen(false)
    if (blocker.state === 'blocked') {
      blocker.proceed()
    }
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
  }

  useEffect(() => {
    if (isSuccessEdit) {
      getAlert({ ...currentFilter.current, pageSize })
        .unwrap()
        .then((result) => {
          currentResult.current = result
          setValue('items', patchItems(result))
        })
        .catch(() => {})
    }
  }, [isSuccessEdit])

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const allowSubmit = noDataChange()
    return !allowSubmit && currentLocation.pathname !== nextLocation.pathname
  })

  const { locations, areas, resetLocationsFilter, getLocationsFilter } =
    useLocationsFilter()

  const watchOrg = watchSearch('organization')

  useEffect(() => {
    if (!isSystemAdmin) {
      getLocationsFilter()
      getAlert({ pageSize })
        .unwrap()
        .then((result) => {
          currentResult.current = result
          setValue('items', patchItems(result))
        })
        .catch(() => {})
    } else {
      triggerOrganizations()
    }
    // Clear the PWA badge count when page is loaded
    clearBadge(navigator)
  }, [])

  useEffect(() => {
    resetLocationsFilter()
    setValueSearch('location', '')
    if (watchOrg && watchOrg !== '') {
      getLocationsFilter(watchOrg)
    }
  }, [watchOrg])

  return (
    <>
      <PageTitle title={t('ALERT')} />
      {isSuccessEdit && (
        <AlertMessage severity="success">
          {t('EDIT_ITEM_SUCCESS', { item: t('ALERT') })}
        </AlertMessage>
      )}
      {isErrorEdit && (
        <AlertMessage severity="error">
          {errorMessage('edit', 'ALERT', errorEdit)}
        </AlertMessage>
      )}

      <SearchFilter
        onSubmit={onSubmitSearch}
        handleSubmit={handleSubmitSearch}
        onReset={() => resetSearch()}
      >
        <Grid item xs={12} md={isSystemAdmin ? 1.5 : 2}>
          <FormDatePicker
            name="start"
            label={t('START_DATE')}
            maxDate={watchSearch('end') ? dayjs(watchSearch('end')) : dayjs()}
            control={controlSearch}
            error={errorsSearch.start}
          />
        </Grid>
        <Grid item xs={12} md={isSystemAdmin ? 1.5 : 2}>
          <FormDatePicker
            name="end"
            label={t('END_DATE')}
            maxDate={dayjs()}
            control={controlSearch}
            error={errorsSearch.end}
            {...(watchSearch('start') && {
              minDate: dayjs(watchSearch('start')),
            })}
          />
        </Grid>
        <Grid item xs={12} md={1.5} lg={2.5}>
          <FormPullDown
            multiple
            name="action"
            label={t('ALERT_ACTIONS')}
            control={controlSearch}
            error={undefined}
            items={actionListTranslated}
          />
        </Grid>
        {isSystemAdmin && (
          <Grid item xs={12} md={2}>
            <FormAutoComplete
              name="organization"
              label={t('ORGANIZATION')}
              control={controlSearch}
              error={errorsSearch.organization}
              items={organizationSearchItems || []}
            />
          </Grid>
        )}
        <Grid item xs={12} md={2.5}>
          <FormAutoComplete
            name="location"
            label={t('LOCATION')}
            control={controlSearch}
            error={errorsSearch.location}
            items={locations.length > 0 ? locations : []}
            groupLabel={areas}
            disabled={locations.length === 0}
          />
        </Grid>
      </SearchFilter>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={4}>
            <RoundButton
              type="submit"
              disabled={isFetching || !data || data.items.length === 0}
            >
              {t('UPDATE_ALERT')}
            </RoundButton>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                {currentFilter.current &&
                  !(currentFilter.current.start && currentFilter.current.end) &&
                  t('DEFAULT_DISPLAYS_ALERTS', {
                    date: currentFilter.current?.start
                      ? `${currentFilter.current.start} - ${dayjs(
                          currentFilter.current.start,
                        )
                          .add(7, 'day')
                          .format('YYYY-MM-DD')}`
                      : currentFilter.current?.end
                        ? `${dayjs(currentFilter.current.end)
                            .subtract(7, 'day')
                            .format('YYYY-MM-DD')} - ${
                            currentFilter.current.end
                          }`
                        : t('LAST_7_DAYS'),
                  })}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {!isFetching &&
          isSuccess &&
          data &&
          data.items.length > 0 &&
          !isLoadingEdit && (
            <>
              <Grid container spacing={2}>
                {fields.map((field, index) => (
                  <Grid item xs={12} sm={6} lg={3} key={field.id}>
                    <AlertCard
                      media={data.includesMedia}
                      data={data.items[index]}
                      control={control}
                      index={index}
                    />
                  </Grid>
                ))}
              </Grid>
              <CenterBox sx={{ mt: 2 }}>
                <Pagination
                  showFirstButton
                  showLastButton
                  page={data.page}
                  count={data.totalPages}
                  onChange={handlePageChange}
                  color="primary"
                />
              </CenterBox>
            </>
          )}
        {!isFetching && isSuccess && data?.items.length === 0 && <NoResults />}
        {isFetching && <Loading />}
      </Box>
      <Modal
        isOpen={isOpen || blocker.state === 'blocked'}
        onClose={handleCloseModal}
        content={t('ERROR_ALERT_WITHOUT_UPDATE')}
        deleteButton
        confirm={handleReset}
        confirmText="CONFIRM_ALERT_WITHOUT_UPDATE"
      />
    </>
  )
}
