import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import router from './router'

export default function App() {
  const { i18n } = useTranslation()

  // Set the html language for the whole app
  useEffect(() => {
    document.documentElement.lang = i18n.language
  }, [i18n.language])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}
