import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import {
  NtagVerifyRequest,
  NtagVerifyResponse,
  NtagGetListResponse,
  NtagCreateRequest,
  NtagGetDetailsResponse,
  NtagPatchRequest,
} from '../types/ntag'

export const ntagApi = createApi({
  baseQuery,
  reducerPath: 'ntagApi',
  tagTypes: ['Ntag'],
  endpoints: (build) => ({
    postNfcVerify: build.mutation<NtagVerifyResponse, NtagVerifyRequest>({
      query: (body) => {
        return {
          url: '/ntags/verify',
          method: 'POST',
          body,
        }
      },
    }),
    getNtagList: build.query<NtagGetListResponse, void>({
      query: () => ({
        url: '/ntags',
        method: 'GET',
      }),
      providesTags: ['Ntag'],
    }),
    getNtagDetails: build.query<NtagGetDetailsResponse, string>({
      query: (id) => ({
        url: `/ntags/${id}`,
        method: 'GET',
      }),
      providesTags: ['Ntag'],
    }),
    postNtagCreate: build.mutation<void, NtagCreateRequest>({
      query: (body) => {
        return {
          url: '/ntags',
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Ntag'],
    }),
    patchNtag: build.mutation<void, NtagPatchRequest>({
      query: ({ id, body }) => {
        return {
          url: `/ntags/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: ['Ntag'],
    }),
    deleteNtag: build.mutation<void, string>({
      query: (id) => ({
        url: `/ntags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Ntag'],
    }),
  }),
})

export const {
  usePostNfcVerifyMutation,
  useLazyGetNtagListQuery,
  useLazyGetNtagDetailsQuery,
  usePostNtagCreateMutation,
  usePatchNtagMutation,
  useDeleteNtagMutation,
} = ntagApi
