import { DefaultEventsMap } from '@socket.io/component-emitter'
import { Socket } from 'socket.io-client'

let socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined

export async function connectSocket() {
  if (!socket) {
    const { io } = await import('socket.io-client')
    const URL = process.env.REACT_APP_API_URL
    if (!URL) {
      throw new Error('SOCKET_IO_URL is not defined')
    }
    socket = io(URL, {
      withCredentials: true,
    })
  }
  return socket
}

export function disconnectSocket() {
  if (socket) {
    socket.disconnect()
    socket = undefined
  }
}
