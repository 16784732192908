import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import RootRoute from '../components/RootRoute'

import MenuLayout from '../layouts/MenuLayout'
import UserRoleForbidden from '../layouts/UserRoleForbidden'

import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import Logout from '../pages/Logout'
import Alerts from '../pages/Alerts'
import Signals from '../pages/Signals'
import Statistics from '../pages/Statistics'
import Logs from '../pages/Logs'
import Organizations from '../pages/Organizations'
import Areas from '../pages/Areas'
import Locations from '../pages/Locations'
import Users from '../pages/Users'
import Notifications from '../pages/Notifications'
import QrScanner from '../pages/QrScanner'
import NfcReader from '../pages/NfcReader'
import Settings from '../pages/Settings'
import Ntags from '../pages/Ntags'
import PageError from '../pages/PageError'

const baseName = { basename: process.env.REACT_APP_BASENAME || '/' }

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <RootRoute />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/',
      element: <MenuLayout />,
      children: [
        {
          path: '/',
          element: <UserRoleForbidden userRole="admin" />,
          children: [
            {
              path: '/users',
              element: <Users />,
            },
          ],
        },
        {
          path: '/',
          element: <UserRoleForbidden userRole="system" />,
          children: [
            {
              path: '/organizations',
              element: <Organizations />,
            },
            {
              path: '/areas',
              element: <Areas />,
            },
            {
              path: '/locations',
              element: <Locations />,
            },
            {
              path: '/ntags',
              element: <Ntags />,
            },
          ],
        },
        {
          path: '/dashboard',
          element: <Dashboard />,
        },
        {
          path: '/alerts',
          element: <Alerts />,
        },
        {
          path: '/signals',
          element: <Signals />,
        },
        {
          path: '/statistics',
          element: <Statistics />,
        },
        {
          path: '/logs',
          element: <Logs />,
        },
        {
          path: '/notifications',
          element: <Notifications />,
        },
        {
          path: '/reset-signal',
          element: <QrScanner />,
        },
        {
          path: '/nfc',
          element: <NfcReader />,
        },
        {
          path: '/settings',
          element: <Settings />,
        },
      ],
    },
    {
      path: '/*',
      element: <PageError />,
    },
  ],
  baseName,
)

export default router
