import React from 'react'
import { Container, Hidden } from '@mui/material'
import { styled } from '@mui/system'
import FullGrid from '../styles/FullGrid.styled'
import CenterGrid from '../styles/CenterGrid.styled'
import CenterBox from '../styles/CenterBox.styled'
import { Box, BoxProps, GridProps } from '@mui/material'

export const BackgroundBox = styled(CenterBox)<BoxProps>(() => ({
  height: '100vh',
  minHeight: '680px',
  width: '100%',
  background:
    'transparent linear-gradient(135deg, #B8FFEE 0%, #A0C7D9 50%, #80A9BA 100%) 0% 0% no-repeat padding-box;',
})) as typeof CenterBox

export const LoginBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '680px',
  borderRadius: '10px',
  background: theme.palette.background.paper,
  width: '100%',
})) as typeof Box

export const ImageGrid = styled(FullGrid)<GridProps>(() => ({
  backgroundImage: 'url(login-background.png)',
  backgroundSize: 'cover',
  backgroundPosition: 'left bottom',
  borderRadius: '10px 0px 0px 10px',
})) as typeof FullGrid

export const LoginFormGrid = styled(CenterGrid)<GridProps>(({ theme }) => ({
  background: theme.palette.background.default,
  flexDirection: 'column',
  // borderRadius: { xs: '10px', md: '0px 10px 10px 0px' },
})) as typeof CenterGrid

export default function LandingLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <>
      <BackgroundBox>
        <Container maxWidth="xl">
          <LoginBox>
            <FullGrid container sx={{ height: '100%' }}>
              <Hidden only={['xs', 'sm']}>
                <ImageGrid item xs={12} md={6} />
              </Hidden>
              <LoginFormGrid
                item
                xs={12}
                md={6}
                sx={{
                  borderRadius: { xs: '10px', md: '0px 10px 10px 0px' },
                }}
              >
                {children}
              </LoginFormGrid>
            </FullGrid>
          </LoginBox>
        </Container>
      </BackgroundBox>
    </>
  )
}
