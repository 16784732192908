import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { styled } from '@mui/system'

const SpaceBetweenBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
})) as typeof Box

export default SpaceBetweenBox
