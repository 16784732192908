import { Card } from '@mui/material'
import { styled } from '@mui/system'

const CardBoard = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
  boxShadow: 'rgba(60, 50, 65, 0.1) 0px 2px 10px 0px',
})) as typeof Card

export default CardBoard
