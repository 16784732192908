import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { BarChart, BarChartProps, PieChart, PieChartProps } from '@mui/x-charts'
import {
  DatasetType,
  DatasetElementType,
} from '@mui/x-charts/models/seriesType/config'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLazyGetStatisticsQuery } from '../redux/alertApi'
import { useLazyGetOrganizationsQuery } from '../redux/organizationApi'
import { useTranslation } from 'react-i18next'
import useLocaleName from '../hooks/useLocaleName'
import useUserDetails from '../hooks/useUserDetails'
import SearchFilter from '../components/SearchFilter'
import { FormDatePicker, FormAutoComplete } from '../components/FormController'
import NoResult from '../components/NoResults'
import PageTitle from '../components/PageTitle'
import Loading from '../components/Loading'
import { StatisticsQueryForm, StatisticsQueryParams } from '../types/alert'
import CardBoard from '../styles/CardBoard.styled'
import CenterBox from '../styles/CenterBox.styled'

export default function Statistics() {
  const { t, i18n } = useTranslation()
  const language = i18n.language
  const getName = useLocaleName()
  const theme = useTheme()
  const { isSystemAdmin } = useUserDetails()

  // Default date range of the statistics
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
  const lastMonth = dayjs().subtract(1, 'week').format('YYYY-MM-DD')

  const [getStat, { data, isFetching }] = useLazyGetStatisticsQuery()

  const [stat, setStat] = useState<DatasetType>([])
  const [series, setSeries] = useState<BarChartProps['series']>([])
  const [pieSeries, setPieSeries] = useState<PieChartProps['series']>([])
  const [display, setDisplay] = useState(false)
  useEffect(() => {
    if (isSystemAdmin) {
      getOrganization()
    } else {
      getStat({
        start: lastMonth,
        end: yesterday,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      const result = data.map((item) => {
        const itemData: DatasetElementType<number | string> = {
          locationName: getName(item.locationName),
          locationCode: item.locationCode,
          total: item.total,
        }
        for (let i = 0; i < item.events.length; i++) {
          const event = item.events[i].event
          itemData[event] = item.events[i].total
        }
        return itemData
      })

      const eventList = data[0].events.map((item) => {
        return {
          dataKey: item.event,
          label: t(item.event.toUpperCase()),
        }
      })

      const pie = data.map((item) => {
        return {
          label: `${getName(item.locationName)} (${item.locationCode})`,
          value: item.total,
        }
      })
      const pieResult = [{ data: pie.sort((a, b) => b.value - a.value) }]
      setStat(result)
      setSeries(eventList)
      setPieSeries(pieResult)
      setDisplay(true)
    } else {
      setDisplay(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, language])

  // Schema of the search filter form
  const searchSchema = yup.object().shape({
    start: yup
      .date()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('START_DATE') })),
    end: yup
      .date()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('END_DATE') })),
    organization: yup.string().when('$isSystemAdmin', {
      is: () => isSystemAdmin,
      then: (schema) =>
        schema.required(
          t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION') }),
        ),
      otherwise: (schema) => schema.optional(),
    }),
  })

  // Search filter React hook form setup
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    formState: { errors: errorsSearch },
    watch: watchSearch,
    reset: resetSearch,
  } = useForm<StatisticsQueryForm>({
    defaultValues: {
      start: new Date(lastMonth),
      end: new Date(yesterday),
      organization: '',
    },
    resolver: yupResolver(searchSchema), // use yup to validate form values
  })

  // Form submit search data handler
  const onSubmitSearch: SubmitHandler<StatisticsQueryForm> = (data) => {
    // Remove the empty fields
    const query: StatisticsQueryParams = {
      start: dayjs(data.start).format('YYYY-MM-DD'),
      end: dayjs(data.end).format('YYYY-MM-DD'),
    }
    if (isSystemAdmin) {
      query.organization = data.organization ? data.organization : undefined
    }
    getStat(query)
  }

  // Get the list of organizations from the API
  const [getOrganization, { data: organizationData }] =
    useLazyGetOrganizationsQuery()

  const organizationSearchItems = organizationData?.map((item) => ({
    value: item.organizationCode,
    label: getName(item.organizationName),
  }))

  const chartColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.primary.dark,
    theme.palette.secondary.dark,
    theme.palette.primary.light,
    theme.palette.secondary.light,
  ]

  const getLocationName = (
    locationCode: string,
    context: { location: string },
  ) => {
    const item = data?.find((item) => item.locationCode === locationCode)
    const name = item ? getName(item.locationName) : locationCode
    return context.location === 'tick' ? name : `${name} (${locationCode})`
  }

  return (
    <>
      <PageTitle title={t('STATISTICS')} />

      <SearchFilter
        onSubmit={onSubmitSearch}
        handleSubmit={handleSubmitSearch}
        onReset={() => resetSearch()}
      >
        <Grid item xs={12} md={2}>
          <FormDatePicker
            name="start"
            label={t('START_DATE')}
            maxDate={watchSearch('end') ? dayjs(watchSearch('end')) : dayjs()}
            control={controlSearch}
            error={errorsSearch.start}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormDatePicker
            name="end"
            label={t('END_DATE')}
            maxDate={dayjs()}
            control={controlSearch}
            error={errorsSearch.end}
            {...(watchSearch('start') && {
              minDate: dayjs(watchSearch('start')),
            })}
          />
        </Grid>
        {isSystemAdmin && (
          <Grid item xs={12} md={2}>
            <FormAutoComplete
              name="organization"
              label={t('ORGANIZATION')}
              control={controlSearch}
              error={errorsSearch.organization}
              items={organizationSearchItems || []}
            />
          </Grid>
        )}
      </SearchFilter>

      {display && !isFetching && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <CardBoard sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h4" sx={{ mt: 2 }}>
                {t('EVENTS_STATISTICS')}
              </Typography>
              <CenterBox sx={{ width: '100%' }}>
                <BarChart
                  layout="horizontal"
                  colors={chartColors}
                  dataset={stat}
                  series={series}
                  yAxis={[
                    {
                      scaleType: 'band',
                      dataKey: 'locationCode',
                      valueFormatter: getLocationName,
                    },
                  ]}
                  width={600}
                  height={stat?.length > 12 ? stat.length * 24 : 600}
                />
              </CenterBox>
            </CardBoard>
          </Grid>
          <Grid item xs={12} lg={5}>
            <CardBoard sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h4" sx={{ mt: 2 }}>
                {t('LOCATIONS_STATISTICS')}
              </Typography>
              <CenterBox sx={{ width: '100%' }}>
                <PieChart
                  colors={chartColors}
                  series={pieSeries}
                  width={400}
                  height={400}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
              </CenterBox>
            </CardBoard>
          </Grid>
        </Grid>
      )}
      {!display && !isFetching && <NoResult />}
      {isFetching && <Loading />}
    </>
  )
}
