import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { UserLogin, UserLoginResponse } from '../types/user'

export const authApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/api',
    credentials: 'include',
  }),
  reducerPath: 'authApi',
  endpoints: (build) => ({
    postLogin: build.mutation<UserLoginResponse, UserLogin>({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    postLogout: build.mutation<void, void>({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
      }),
    }),
  }),
})

export const { usePostLoginMutation, usePostLogoutMutation } = authApi
