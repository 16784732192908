/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Grid, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import * as yup from 'yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLazyGetLogsQuery } from '../redux/alertApi'
import { useLazyGetOrganizationsQuery } from '../redux/organizationApi'
import useDataGridOptions from '../hooks/useDataGridOptions'
import useLocaleName from '../hooks/useLocaleName'
import useUserDetails from '../hooks/useUserDetails'
import useLocationsFilter from '../hooks/useLocationsFilter'
import { LogsQueryParams, LogsQueryForm } from '../types/alert'
import { FormDatePicker, FormAutoComplete } from '../components/FormController'
import SearchFilter from '../components/SearchFilter'
import PageTitle from '../components/PageTitle'

export default function Logs() {
  const { t } = useTranslation()
  const getName = useLocaleName()
  const { dataGridProps } = useDataGridOptions()
  const { isSystemAdmin } = useUserDetails()

  const [currentFilter, setCurrentFilter] = useState<LogsQueryParams | null>(
    null,
  )

  // Default date range of the statistics
  const today = dayjs().format('YYYY-MM-DD')
  const lastMonth = dayjs().subtract(1, 'week').format('YYYY-MM-DD')

  const [getLogs, { data, isFetching }] = useLazyGetLogsQuery()

  const [getOrganizations, { data: organizationData }] =
    useLazyGetOrganizationsQuery()

  const organizations = organizationData?.map((item) => ({
    value: item.organizationCode,
    label: getName(item.organizationName),
  }))

  const organizationSearchItems = [
    { value: 'system', label: t('SYSTEM') },
    ...(organizations || []),
  ]

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: t('USERNAME'),
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        if (params.row.isUser) {
          return params.value
        }
        return t('SYSTEM_CREATED')
      },
    },
    {
      field: 'locationName',
      headerName: t('LOCATION_NAME'),
      flex: 1.5,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value !== undefined) {
          return getName(params.value)
        } else {
          return ''
        }
      },
    },
    {
      field: 'operation',
      headerName: t('OPERATION'),
      flex: 1,
      valueFormatter: (params) => {
        switch (params.value) {
          case 'createAlert':
            return t('CREATE_NEW_ITEM', { item: t('ALERT') })
          case 'editAction':
            return t('UPDATE_ALERT')
          case 'editComment':
            return t('EDIT_ITEM', { item: t('ALERT_COMMENTS') })
          case 'deleteComment':
            return t('DELETE_ITEM', { item: t('ALERT_COMMENTS') })
          case 'cameraOnline':
            return t('CAMERA_STATUS')
          case 'cameraOffline':
            return t('CAMERA_STATUS')
          case 'resetSignal':
            return t('RESET_SIGNAL')
          case 'userLogin':
            return t('USER_LOGIN')
          case 'ntagVerify':
            return t('NTAG_VERIFY')
          default:
            return ''
        }
      },
    },
    {
      field: 'event',
      headerName: t('EVENT'),
      flex: 1,
      renderCell: (params) => {
        switch (params.value) {
          case 'cameraOnline':
            return (
              <Typography variant="body2" sx={{ color: 'success.main' }}>
                {t('ONLINE')}
              </Typography>
            )
          case 'cameraOffline':
            return (
              <Typography variant="body2" sx={{ color: 'error.main' }}>
                {t('OFFLINE')}
              </Typography>
            )
          case '':
            return <></>
          case undefined:
            return <></>
          default:
            return <>{params.value ? t(params.value.toUpperCase()) : ''}</>
        }
      },
    },
    {
      field: 'description',
      headerName: t('DESCRIPTION'),
      flex: 1.5,
      sortable: false,
      renderCell: (params) => {
        if (params.row.operation === 'createAlert') {
          const state = params.value
          return (
            <>
              {state && (
                <Typography
                  variant="body2"
                  sx={{
                    color: state === 'danger' ? 'error.main' : 'warning.main',
                  }}
                >
                  {t(state.toUpperCase())}
                </Typography>
              )}
            </>
          )
        } else if (
          params.row.operation === 'ntagVerify' &&
          params.row.ntagCode
        ) {
          return <Typography variant="body2">{params.row.ntagCode}</Typography>
        } else {
          return params.value
        }
      },
    },
    {
      field: 'ip',
      headerName: t('IP_ADDRESS'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'timestamp',
      headerName: t('TIMESTAMP'),
      flex: 1,
      valueFormatter: (params) => {
        return dayjs(params.value).format('YYYY-MM-DD HH:mm')
      },
    },
  ]

  useEffect(() => {
    if (isSystemAdmin) {
      getOrganizations()
    } else {
      getLogs({
        start: lastMonth,
        end: today,
        pageSize: 10,
        page: 1,
      })
      setCurrentFilter({
        start: lastMonth,
        end: today,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Schema of the search filter form
  const searchSchema = yup.object().shape({
    organization: yup.string().when('$isSystemAdmin', {
      is: () => isSystemAdmin,
      then: (schema) =>
        schema.required(
          t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION') }),
        ),
      otherwise: (schema) => schema.optional(),
    }),
    location: yup.string().optional().nullable(),
    start: yup
      .date()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('START_DATE') })),
    end: yup
      .date()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('END_DATE') })),
    page: yup.number().optional(),
    pageSize: yup.number().optional(),
  })

  // Search filter React hook form setup
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    formState: { errors: errorsSearch },
    setValue: setValueSearch,
    watch: watchSearch,
    reset: resetSearch,
  } = useForm<LogsQueryForm>({
    defaultValues: {
      organization: '',
      location: '',
      start: new Date(lastMonth),
      end: new Date(today),
      page: 1,
      pageSize: 10,
    },
    resolver: yupResolver(searchSchema), // use yup to validate form values
  })

  // Form submit search data handler
  const onSubmitSearch: SubmitHandler<LogsQueryForm> = (data) => {
    const { start, end, location, ...others } = data
    const filteredData: LogsQueryParams = {
      ...others,
      location: location || undefined,
      start: dayjs(start).format('YYYY-MM-DD'),
      end: dayjs(end).format('YYYY-MM-DD'),
    }
    if (!location || location === '') {
      delete filteredData.location
    }
    if (!isSystemAdmin) {
      delete filteredData.organization
    }
    getLogs(filteredData)
      .unwrap()
      .then(() => {
        setCurrentFilter(filteredData)
      })
      .catch(() => {})
  }

  const paginationModel = {
    page: data ? data.page - 1 : 0,
    pageSize: data?.pageSize || 10,
  }

  const changePage = async (model: GridPaginationModel) => {
    const { page, pageSize } = model
    if (currentFilter) {
      await getLogs({ ...currentFilter, page: page + 1, pageSize: pageSize })
    }
  }

  const { locations, areas, resetLocationsFilter, getLocationsFilter } =
    useLocationsFilter()

  const watchOrg = watchSearch('organization')

  useEffect(() => {
    if (!isSystemAdmin) {
      getLocationsFilter()
    }
  }, [])

  useEffect(() => {
    resetLocationsFilter()
    setValueSearch('location', '')
    if (watchOrg && watchOrg !== '' && watchOrg !== 'system') {
      getLocationsFilter(watchOrg)
    }
  }, [watchOrg])

  return (
    <>
      <PageTitle title={t('LOGS')} />

      <SearchFilter
        onSubmit={onSubmitSearch}
        handleSubmit={handleSubmitSearch}
        onReset={() => resetSearch()}
      >
        <Grid item xs={12} md={2}>
          <FormDatePicker
            name="start"
            label={t('START_DATE')}
            maxDate={watchSearch('end') ? dayjs(watchSearch('end')) : dayjs()}
            control={controlSearch}
            error={errorsSearch.start}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormDatePicker
            name="end"
            label={t('END_DATE')}
            maxDate={dayjs()}
            control={controlSearch}
            error={errorsSearch.end}
            {...(watchSearch('start') && {
              minDate: dayjs(watchSearch('start')),
            })}
          />
        </Grid>
        {isSystemAdmin && (
          <Grid item xs={12} md={2}>
            <FormAutoComplete
              name="organization"
              label={t('ORGANIZATION')}
              control={controlSearch}
              error={errorsSearch.organization}
              items={organizationSearchItems || []}
            />
          </Grid>
        )}
        <Grid item xs={12} md={2.5}>
          <FormAutoComplete
            name="location"
            label={t('LOCATION')}
            control={controlSearch}
            error={errorsSearch.location}
            items={locations.length > 0 ? locations : []}
            groupLabel={areas}
            disabled={locations.length === 0}
          />
        </Grid>
      </SearchFilter>

      <DataGrid
        {...dataGridProps}
        rows={data ? data.items : []}
        rowCount={data?.total || 0}
        columns={columns}
        loading={isFetching}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={changePage}
        pageSizeOptions={[10, 20, 50, 100]}
      />
    </>
  )
}
