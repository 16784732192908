import React, { useState } from 'react'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import NfcOutlinedIcon from '@mui/icons-material/NfcOutlined'
import Modal from './Modal'

export function GridEditAction({ onEdit }: { onEdit: () => void }) {
  const { t } = useTranslation()
  return (
    <GridActionsCellItem
      icon={<EditOutlinedIcon />}
      label={t('EDIT')}
      color="primary"
      onClick={onEdit}
    />
  )
}

export function GridTestAction({
  onTest,
  testMode,
}: {
  onTest: () => void
  testMode: boolean
}) {
  const { t } = useTranslation()
  return (
    <GridActionsCellItem
      icon={<ScienceOutlinedIcon />}
      label={t('TEST_MODE')}
      color={testMode ? 'success' : 'info'}
      onClick={onTest}
    />
  )
}

export function GridFloorPlanAction({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled: boolean
}) {
  const { t } = useTranslation()
  return (
    <GridActionsCellItem
      icon={<ImageOutlinedIcon />}
      label={t('EDIT_ITEM', { item: t('FLOOR_PLAN') })}
      color="primary"
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export function GridConfigNtagAction({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled?: boolean
}) {
  const { t } = useTranslation()
  return (
    <GridActionsCellItem
      icon={<NfcOutlinedIcon />}
      label={t('EDIT_ITEM', { item: t('NTAG') })}
      color="primary"
      onClick={onClick}
      disabled={disabled}
    />
  )
}

export function GridDeleteAction({
  action,
  name,
  disabled = false,
  onDelete,
}: {
  action: string
  name: string
  disabled?: boolean
  onDelete: () => Promise<void>
}) {
  const { t } = useTranslation()
  const [openDelete, setOpenDelete] = useState(false)
  const handleConfirmDelete = async () => {
    await onDelete()
    setOpenDelete(false)
  }

  return (
    <>
      <GridActionsCellItem
        icon={<DeleteOutlinedIcon />}
        label={t('DELETE')}
        color="error"
        disabled={disabled}
        onClick={() => setOpenDelete(true)}
      />
      <Modal
        content={t('DELETE_WARNING', { action, name })}
        deleteButton
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        confirm={() => handleConfirmDelete()}
        confirmText="DELETE"
      />
    </>
  )
}
