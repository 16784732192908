import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material'
import { styled } from '@mui/system'

const CenterBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})) as typeof Box

export default CenterBox
