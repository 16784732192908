import React from 'react'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { usePutUserMutation } from '../redux/userApi'
import { UserFormChangePassword } from '../types/user'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FormTextField } from '../components/FormController'
import PageTitle from '../components/PageTitle'
import RoundButton from '../styles/RoundButton.styled'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import useErrorMessage from '../hooks/useErrorMessage'
import AlertMessage from '../styles/AlertMessage.styled'

export default function Settings() {
  const { t } = useTranslation()
  const errorMessage = useErrorMessage()
  // Schema of the form
  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('OLD_PASSWORD') })),
    password: yup
      .string()
      .min(8, t('ERROR_INPUT_MIN_LENGTH', { field: t('NEW_PASSWORD'), min: 8 }))
      .required(t('ERROR_INPUT_REQUIRED', { field: t('NEW_PASSWORD') })),
    confirmPassword: yup
      .string()
      .min(
        8,
        t('ERROR_INPUT_MIN_LENGTH', { field: t('CONFIRM_PASSWORD'), min: 8 }),
      )
      .required(t('ERROR_INPUT_REQUIRED', { field: t('CONFIRM_PASSWORD') }))
      .test('passwords-match', t('ERROR_PASSWORD_MISMATCH'), function (value) {
        return this.parent.password === value
      }),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<UserFormChangePassword>({
    resolver: yupResolver(schema), // use yup to validate form values
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  })

  const [putUser, { isLoading, isSuccess, isError, error }] =
    usePutUserMutation()

  // Form submit handler
  const onSubmit: SubmitHandler<UserFormChangePassword> = async (data) => {
    await putUser(data)
      .unwrap()
      .then(() => {
        reset()
      })
      .catch(() => {})
  }

  return (
    <>
      <PageTitle title={t('SETTINGS')} />
      <Typography variant="h3" component="h2">
        {t('CHANGE_PASSWORD')}
      </Typography>
      {isSuccess && (
        <AlertMessage severity="success">
          {t('CHANGE_PASSWORD_SUCCESS')}
        </AlertMessage>
      )}
      {isError &&
        ((error as FetchBaseQueryError).status === 400 ||
          (error as FetchBaseQueryError).status === 403) && (
          <AlertMessage severity="error">
            {errorMessage('edit', 'PASSWORD', error)}
          </AlertMessage>
        )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ maxWidth: 400 }}
      >
        <FormTextField
          name="oldPassword"
          label={t('OLD_PASSWORD')}
          isPassword
          disabled={isLoading}
          control={control}
          error={errors.oldPassword}
        />
        <FormTextField
          name="password"
          label={t('NEW_PASSWORD')}
          isPassword
          disabled={isLoading}
          control={control}
          error={errors.password}
        />
        <FormTextField
          name="confirmPassword"
          label={t('CONFIRM_PASSWORD')}
          isPassword
          disabled={isLoading}
          control={control}
          error={errors.confirmPassword}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <RoundButton type="submit" color="primary" disabled={isLoading}>
            {t('SUBMIT')}
          </RoundButton>
          <RoundButton color="info" onClick={() => reset()}>
            {t('RESET')}
          </RoundButton>
        </Box>
      </Box>
    </>
  )
}
