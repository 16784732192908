import React, { useState } from 'react'
import { Box, Grid, Button } from '@mui/material'
import RoundButton from '../styles/RoundButton.styled'
import VerticalCenterBox from '../styles/VerticalCenterBox.styled'
import { useTranslation } from 'react-i18next'
import { FieldValues, UseFormReturn, SubmitHandler } from 'react-hook-form'

type FormProps<TFormValues extends FieldValues> = {
  children?: React.ReactNode
  isLoading?: boolean
  onSubmit: SubmitHandler<TFormValues>
  handleSubmit: UseFormReturn<TFormValues>['handleSubmit']
  onReset?: () => void
}

export default function SearchFilter<TFormValues extends FieldValues>({
  children,
  isLoading = false,
  onSubmit,
  handleSubmit,
  onReset,
}: FormProps<TFormValues>) {
  const { t } = useTranslation()
  const [display, setDisplay] = useState(false)

  const handleDisplay = () => {
    setDisplay(!display)
  }
  return (
    <Box py={1}>
      <Button
        variant="text"
        size="small"
        onClick={handleDisplay}
        sx={{ display: { xs: 'block', md: 'none' }, mb: 1 }}
      >
        {display ? t('HIDE_SEARCH_FILTER') : t('SHOW_SEARCH_FILTER')}
      </Button>
      <Box
        id="search-filter"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: { xs: display ? 'block' : 'none', md: 'block' },
        }}
      >
        <Grid container spacing={1}>
          {children}
          <Grid item xs={12} md={3} lg={2} xl={1.5}>
            <VerticalCenterBox sx={{ height: '100%', gap: 1, pb: 2 }}>
              <RoundButton type="submit" color="primary" disabled={isLoading}>
                {t('SEARCH')}
              </RoundButton>
              <RoundButton onClick={onReset} color="info">
                {t('RESET')}
              </RoundButton>
            </VerticalCenterBox>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
