/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Middleware,
  MiddlewareAPI,
  isFulfilled,
  isRejectedWithValue,
} from '@reduxjs/toolkit'

import { setFetchError } from './generalSlice'

const queryError: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const fetchErrorState = api.getState().general.fetchError
  if (isRejectedWithValue(action) && !fetchErrorState) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = action.payload as any
    if (typeof payload.status === 'string') {
      api.dispatch(setFetchError(true))
    }
  } else if (isFulfilled(action) && fetchErrorState) {
    api.dispatch(setFetchError(false))
  }
  return next(action)
}

export default queryError
