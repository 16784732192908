/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Box,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import TextDataRow from './TextDataRow'
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded'
import { AlertItem } from '../types/alert'
import useLocaleName from '../hooks/useLocaleName'
import { FormTextField } from './FormController'
import { Controller, Control, FieldValues } from 'react-hook-form'
import { actionList } from '../utils/global'
import ImageViewer from './ImageViewer'
import SpaceBetweenBox from '../styles/SpaceBetweenBox.styled'

// Style of the Card
const AlertCardBoard = styled(Box)(() => ({
  display: 'flex',
})) as typeof Box

interface AlertCardProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> {
  media: boolean
  data: AlertItem
  control: Control<TFieldValues, TContext>
  index: number
  // name: any
}

export default function AlertCard<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>({
  media,
  data,
  control,
  index,
}: AlertCardProps<TFieldValues, TContext>): JSX.Element {
  const theme = useTheme()
  const { t } = useTranslation()
  const eventName = t(data.event.toUpperCase())
  const date = dayjs(data.timestamp).format('DD/MM/YYYY')
  const time = dayjs(data.timestamp).format('HH:mm:ss')
  const getName = useLocaleName()

  const [openImage, setOpenImage] = React.useState<boolean>(false)

  return (
    <Card elevation={4} sx={{ borderRadius: '12px' }}>
      {media && data.mediaVideo && (
        <CardMedia
          component="video"
          controls
          height="210"
          poster={data.mediaImage || undefined}
          src={data.mediaVideo}
        />
      )}
      {media && !data.mediaVideo && data.mediaImage && (
        <CardMedia
          component="img"
          height="210"
          image={data.mediaImage}
          alt="No Image"
        />
      )}
      {media && !data.mediaVideo && !data.mediaImage && (
        <CardMedia
          component="img"
          height="210"
          image="/logo.png"
          alt="No Image"
        />
      )}
      <AlertCardBoard sx={{ display: 'flex' }}>
        {data.state === 'danger' && (
          <Box
            sx={{ backgroundColor: theme.palette.error.main, width: '4px' }}
          />
        )}
        {data.state === 'warning' && (
          <Box
            sx={{ backgroundColor: theme.palette.warning.main, width: '4px' }}
          />
        )}
        <CardContent
          sx={{
            paddingBottom: `${theme.spacing(2)}!important`,
            width: '100%',
          }}
        >
          <SpaceBetweenBox>
            <>
              {data.state === 'danger' && (
                <TextDataRow
                  icon={DangerousRoundedIcon}
                  label={eventName}
                  color="error"
                  size="1.2rem"
                />
              )}
              {data.state === 'warning' && (
                <TextDataRow
                  icon={WarningRoundedIcon}
                  label={eventName}
                  color="warning"
                  size="1.2rem"
                />
              )}
            </>
            {data.mediaImage && (
              <IconButton
                sx={{ height: '32px', width: '32px' }}
                onClick={() => setOpenImage(true)}
              >
                <InsertPhotoRoundedIcon fontSize="small" />
              </IconButton>
            )}
          </SpaceBetweenBox>

          <>
            <TextDataRow
              icon={LocationOnRoundedIcon}
              text={getName(data.locationName)}
            />
            <TextDataRow
              icon={CalendarTodayRoundedIcon}
              text={date + ' ' + time}
            />
          </>
          <CardActions sx={{ p: 0 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                width: '100%',
              }}
            >
              <Controller
                control={control}
                name={`items.${index}.action` as any}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {actionList.map((action) => (
                      <FormControlLabel
                        key={action.value}
                        value={action.value}
                        control={<Radio size="small" color={action.color} />}
                        label={
                          <Typography
                            variant="body1"
                            sx={{ fontSize: '0.75rem' }}
                          >
                            {t(action.label)}
                          </Typography>
                        }
                        sx={{
                          display: action.value === 'pending' ? 'none' : 'flex',
                        }}
                        disabled={action.value === 'pending'}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Box>
          </CardActions>
          <FormTextField
            name={`items.${index}.comment` as any}
            label={t('ALERT_COMMENTS')}
            control={control}
            error={undefined}
            multiline
            row={2}
            mb={0}
          />
        </CardContent>
      </AlertCardBoard>
      <ImageViewer
        open={openImage}
        onClose={() => setOpenImage(false)}
        imagePath={data.mediaImage}
      />
    </Card>
  )
}
