import React from 'react'
import { Box, Dialog, DialogContent, IconButton } from '@mui/material'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import SpaceBetweenBox from '../styles/SpaceBetweenBox.styled'
import CenterBox from '../styles/CenterBox.styled'

export default function ImageViewer({
  open,
  onClose,
  imagePath,
}: {
  open: boolean
  onClose: () => void
  imagePath?: string
}) {
  return (
    <Dialog open={open} PaperComponent={Box} fullScreen>
      <DialogContent sx={{ width: '100%', height: '100vh' }}>
        <SpaceBetweenBox py={1}>
          <Box></Box>
          <IconButton onClick={onClose} sx={{ color: 'white' }}>
            <CancelRoundedIcon />
          </IconButton>
        </SpaceBetweenBox>
        <CenterBox sx={{ width: '100%', height: '90%' }}>
          <img
            src={imagePath}
            alt="image"
            style={{
              objectFit: 'contain',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </CenterBox>
      </DialogContent>
    </Dialog>
  )
}
