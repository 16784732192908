/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageTitle from '../../components/PageTitle'
import { GridEditAction, GridDeleteAction } from '../../components/GridActions'
import {
  useLazyGetNtagListQuery,
  useDeleteNtagMutation,
} from '../../redux/ntagApi'
import useLocaleName from '../../hooks/useLocaleName'
import useDataGridOptions from '../../hooks/useDataGridOptions'
import ShadowDataGrid from '../../styles/ShadowDataGrid.styled'
import RoundButton from '../../styles/RoundButton.styled'
import CreateNtag from './CreateNtag'

import type { GridColDef } from '@mui/x-data-grid'
import type { DataType } from '../../types/global'
// import type { NtagListItem } from '../../types/ntag'

export default function Ntags() {
  const { t } = useTranslation()
  const getName = useLocaleName()
  const { dataGridProps } = useDataGridOptions()

  const [dataType, setDataType] = useState<DataType>('create')
  const [openData, setOpenData] = useState<boolean>(false)
  const [editData, setEditData] = useState<string>('')

  const [getNtagList, { data: ntagList, isSuccess, isFetching }] =
    useLazyGetNtagListQuery()

  const [deleteNtag] = useDeleteNtagMutation()

  const columns: GridColDef[] = [
    {
      field: 'ntagCode',
      headerName: t('NTAG_CODE'),
      flex: 2,
    },
    {
      field: 'uid',
      headerName: t('NTAG_UID'),
      flex: 2,
    },
    {
      field: 'organizationName',
      headerName: t('ORGANIZATION_NAME'),
      flex: 3,
      sortable: false,
      renderCell: (params) => {
        return <>{getName(params.row.organizationName)}</>
      },
    },
    {
      field: 'enabled',
      headerName: t('ENABLED'),
      flex: 2,
      renderCell: (params) => t(params.row.enabled ? 'TRUE' : 'FALSE'),
    },
    {
      field: 'actions',
      headerName: t('ACTIONS'),
      flex: 1,
      type: 'actions',
      getActions: (params) => {
        const onEdit = () => {
          setDataType('edit')
          setOpenData(true)
          setEditData(params.row.id)
        }
        const onDelete = async () => {
          await deleteNtag(params.row.id)
        }
        return [
          <GridEditAction key="edit" onEdit={onEdit} />,
          <GridDeleteAction
            key="delete"
            action={t('NTAG')}
            name={params.row.ntagCode}
            onDelete={onDelete}
          />,
        ]
      },
    },
  ]

  const handleOpenNewData = () => {
    setDataType('create')
    setOpenData(true)
  }

  const newText = t('CREATE_NEW_ITEM', { item: t('NTAG') })

  useEffect(() => {
    getNtagList()
  }, [])

  return (
    <>
      <PageTitle title={t('NTAGS')} />
      <RoundButton sx={{ mb: 2 }} onClick={handleOpenNewData}>
        {newText}
      </RoundButton>
      <CreateNtag
        openData={openData}
        setOpenData={setOpenData}
        dataType={dataType}
        editData={editData}
      />
      <ShadowDataGrid
        {...dataGridProps}
        rows={isSuccess && ntagList ? ntagList : []}
        columns={columns}
        loading={isFetching}
      />
    </>
  )
}
