/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Box, Typography, Checkbox } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import QRCode from 'react-qr-code'
import { toPng } from 'html-to-image'
import dayjs from 'dayjs'
import useLocaleName from '../hooks/useLocaleName'
import useErrorMessage from '../hooks/useErrorMessage'
import useDataGridOptions from '../hooks/useDataGridOptions'
import RoundButton from '../styles/RoundButton.styled'
import AlertMessage from '../styles/AlertMessage.styled'
import CenterBox from '../styles/CenterBox.styled'
import {
  useLazyGetLocationsQuery,
  useLazyGetLocationDetailsQuery,
  usePostLocationMutation,
  usePatchLocationMutation,
  useDeleteLocationMutation,
  useCreateResetSignalMutation,
} from '../redux/locationApi'
import {
  useLazyGetAreasQuery,
  useLazyGetFloorPlanQuery,
} from '../redux/areaApi'
import {
  GridEditAction,
  GridFloorPlanAction,
  GridTestAction,
  GridDeleteAction,
} from '../components/GridActions'
import NewEditData from '../components/NewEditData'
import { FormTextField, FormSwitch } from '../components/FormController'
import PageTitle from '../components/PageTitle'
import Modal from '../components/Modal'
import { LocationFormItem, LocationPatchItem } from '../types/location'
import { DataType } from '../types/global'
import { useLocation, useNavigate } from 'react-router-dom'
import { AreaListItem } from '../types/area'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'

export default function Locations() {
  const { t } = useTranslation()
  const { dataGridProps } = useDataGridOptions()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  const getName = useLocaleName()

  const [openData, setOpenData] = useState(false)
  const [dataType, setDataType] = useState<DataType>('create')
  const [isLoading, setIsLoading] = useState(false)
  const areaItem = useRef<AreaListItem | null>(null)
  const locationId = useRef<string | null>(null)
  const [openFloorPlan, setOpenFloorPlan] = useState(false)
  const [floorPlanX, setFloorPlanX] = useState<number | null>(null)
  const [floorPlanY, setFloorPlanY] = useState<number | null>(null)
  const [floorPlanTop, setFloorPlanTop] = useState<number | null>(null)
  const [floorPlanLeft, setFloorPlanLeft] = useState<number | null>(null)
  const [imageLoaded, setImageLoaded] = useState(false)

  const params = new URLSearchParams(location.search)
  const areaCode = params.get('area')

  useEffect(() => {
    if (areaCode) {
      triggerGetList({ area: areaCode })
      triggerGetArea({ code: areaCode })
        .unwrap()
        .then((data) => {
          areaItem.current = data[0]
        })
    }
  }, [])

  // Close the form dialog
  const handleCloseData = () => {
    setIsLoading(false)
    setOpenData(false)
  }

  useEffect(() => {
    if (!openData) {
      resetForm()
    }
  }, [openData])

  // Open the form dialog
  const handleOpenData = () => {
    setIsLoading(false)
    setOpenData(true)
  }

  const handleOpenNewData = () => {
    setDataType('create')
    if (areaItem.current !== null) {
      setValue('areaId', areaItem.current.id)
    }
    handleOpenData()
  }

  const handleEditData = () => {
    setDataType('edit')
    handleOpenData()
  }

  const handleBack = () => {
    navigate(-1)
  }

  // Get the areaId from the API
  const [triggerGetArea] = useLazyGetAreasQuery()

  // Get the list of locations from the API
  const [triggerGetList, { data: listData, isFetching }] =
    useLazyGetLocationsQuery()

  // Get the list of locations from the API
  const [trigger] = useLazyGetLocationDetailsQuery()

  // Get the floor plan from the API
  const [
    triggerGetFloorPlan,
    { data: floorPlan, isSuccess: isSuccessFloorPlan },
  ] = useLazyGetFloorPlanQuery()

  // Create location mutation
  const [
    postLocation,
    {
      isSuccess: isSuccessNew,
      isLoading: isLoadingNew,
      isError: isErrorNew,
      error: errorNew,
      reset: resetNew,
    },
  ] = usePostLocationMutation()

  // Edit location mutation
  const [
    patchLocation,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = usePatchLocationMutation()

  // Delete location mutation
  const [
    deleteLocation,
    {
      isSuccess: isSuccessDelete,
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: errorDelete,
      reset: resetDelete,
    },
  ] = useDeleteLocationMutation()

  const [
    createQr,
    {
      data: createQrRes,
      isSuccess: isSuccessQr,
      isLoading: isLoadingQr,
      isError: isErrorQr,
      reset: resetQr,
    },
  ] = useCreateResetSignalMutation()

  // Reset the mutation states
  const resetAll = () => {
    setIsLoading(true)
    resetNew()
    resetEdit()
    resetDelete()
    resetQr()
    locationId.current = null
  }

  // Schema of the form
  const schema = yup.object().shape({
    id: yup.string().optional(),
    locationCode: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('LOCATION_CODE') })),
    locationName: yup.object().shape({
      en: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('LOCATION_NAME_LOCALE', { locale: t('EN') }),
        }),
      ),
      zhHk: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('LOCATION_NAME_LOCALE', { locale: t('ZH_HK') }),
        }),
      ),
      zhCn: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('LOCATION_NAME_LOCALE', { locale: t('ZH_CN') }),
        }),
      ),
    }),
    areaId: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('LOCATION') })),
    testMode: yup
      .boolean()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('TEST_MODE') })),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm<LocationFormItem>({
    defaultValues: {
      id: '',
      locationCode: '',
      locationName: {
        en: '',
        zhHk: '',
        zhCn: '',
      },
      areaId: '',
      testMode: false,
    },
    resolver: yupResolver(schema), // use yup to validate form values
  })

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {},
  )

  const [qrModal, setQrModal] = useState(false)

  const handleCheckboxChange =
    (code: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedItems({
        ...checkedItems,
        [code]: event.target.checked,
      })
    }

  const generateQrCode = () => {
    if (areaItem.current) {
      const locationCodes: string[] = []
      Object.keys(checkedItems).forEach((key) => {
        if (checkedItems[key]) {
          locationCodes.push(key)
        }
      })
      if (locationCodes.length === 0) {
        alert(t('NO_LOCATION_SELECTED'))
        return
      }
      const data = {
        organizationCode: areaItem.current.organizationCode,
        locationCodes,
      }
      setQrModal(true)
      createQr(data)
    }
  }

  const downloadQr = async () => {
    if (!isSuccessQr) {
      return
    }
    const qrCode = document.getElementById('qr-code')
    if (qrCode) {
      const png = await toPng(qrCode)
      const a = document.createElement('a')
      a.href = png
      a.download = 'qr-code.png'
      a.click()
    }
  }

  const handleCloseQrModal = () => {
    setQrModal(false)
    resetAll()
    setCheckedItems({})
  }

  // Define the columns of the data grid
  const columns: GridColDef[] = [
    {
      field: 'checkbox',
      headerName: '',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        const code = params.row.locationCode
        return (
          <Checkbox
            color="primary"
            checked={checkedItems[code] || false}
            onChange={handleCheckboxChange(code)}
          />
        )
      },
    },
    {
      field: 'locationCode',
      headerName: t('LOCATION_CODE'),
      flex: 1,
    },
    {
      field: 'locationName',
      headerName: t('LOCATION_NAME'),
      flex: 2,
      sortable: false,
      valueFormatter: (params) => {
        return getName(params.value)
      },
    },
    {
      field: 'health',
      headerName: t('LOCATION_HEALTH'),
      flex: 1,
      renderCell: (params) => {
        const health = params.row.health
        return (
          <Typography
            sx={{
              color:
                health === 'online'
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            {health && t(health.toUpperCase())}
          </Typography>
        )
      },
    },
    {
      field: 'lastHeartbeat',
      headerName: t('LAST_HEARTBEAT'),
      flex: 1.5,
      valueFormatter: (params) => {
        const lastHeartbeat = params.value
        return lastHeartbeat
          ? dayjs(lastHeartbeat).format('YYYY-MM-DD HH:mm:ss')
          : ''
      },
    },
    {
      field: 'floorPlanPosition',
      headerName: t('FLOOR_PLAN_POSITION'),
      flex: 1,
      renderCell: (params) => {
        const x = params.row.floorPlanX
        const y = params.row.floorPlanY
        return x && y ? t('TRUE') : t('FALSE')
      },
    },
    {
      field: 'testMode',
      headerName: t('TEST_MODE'),
      flex: 1,
      valueFormatter: (params) => {
        return t(params.value.toString().toUpperCase())
      },
    },
    {
      field: 'actions',
      headerName: t('ACTIONS'),
      flex: 1,
      minWidth: 160,
      type: 'actions',
      getActions: (params) => {
        const onEdit = () => {
          resetAll()
          trigger(params.id.toString(), false)
            .unwrap()
            .then((data) => {
              setValue('id', data.id)
              setValue('locationCode', data.locationCode)
              setValue('locationName', data.locationName)
              setValue('testMode', data.testMode)
              if (areaItem.current !== null) {
                setValue('areaId', areaItem.current.id)
              }
              handleEditData()
            })
            .catch(() => {})
        }
        const getFloorPlan = async () => {
          resetAll()
          if (
            areaItem.current &&
            (areaItem.current.floorPlan !== '' || !areaItem.current.floorPlan)
          ) {
            locationId.current = params.row.id
            await triggerGetFloorPlan(areaItem.current.id).unwrap()
            trigger(params.id.toString(), false)
              .unwrap()
              .then((data) => {
                if (data.floorPlanX && data.floorPlanY) {
                  setFloorPlanX(data.floorPlanX)
                  setFloorPlanY(data.floorPlanY)
                }
              })
              .catch(() => {})
            setOpenFloorPlan(true)
          }
        }
        const onTest = async () => {
          resetAll()
          const patchData: LocationPatchItem = {
            id: params.row.id,
            testMode: !params.row.testMode,
          }
          await patchLocation(patchData)
        }
        const onDelete = async () => {
          resetAll()
          await deleteLocation(params.id.toString())
        }
        return [
          <GridEditAction key="edit" onEdit={onEdit} />,
          <GridFloorPlanAction
            key="floor-plan"
            disabled={
              !areaItem.current ||
              areaItem.current.floorPlan === '' ||
              !areaItem.current.floorPlan
            }
            onClick={getFloorPlan}
          />,
          <GridTestAction
            key="test"
            testMode={params.row.testMode}
            onTest={onTest}
          />,
          <GridDeleteAction
            key="delete"
            action={t('LOCATION')}
            name={getName(params.row.locationName)}
            onDelete={onDelete}
          />,
        ]
      },
    },
  ]

  // Refresh the data grid when a location is deleted
  useEffect(() => {
    if (isSuccessNew || isSuccessEdit || isSuccessDelete) {
      setIsLoading(false)
      if (areaCode) {
        triggerGetList({ area: areaCode })
      }
    }
  }, [isSuccessNew, isSuccessEdit, isSuccessDelete])

  useEffect(() => {
    if (isErrorNew || isErrorEdit || isErrorDelete) {
      setIsLoading(false)
    }
  }, [isErrorNew, isErrorEdit, isErrorDelete])

  // Form submit data handler
  const onSubmit: SubmitHandler<LocationFormItem> = async (data) => {
    resetAll()
    if (dataType === 'create') {
      // Create new location
      const postData = { ...data }
      delete postData.id
      await postLocation(postData)
        .unwrap()
        .then(() => {
          handleCloseData()
        })
        .catch(() => {})
    } else {
      // Edit location
      if (data.id !== undefined) {
        const patchData: LocationPatchItem = {
          id: data.id,
          locationName: data.locationName,
          testMode: data.testMode,
        }
        await patchLocation(patchData)
          .unwrap()
          .then(() => {
            handleCloseData()
          })
          .catch(() => {})
      }
    }
  }

  const newText = t('CREATE_NEW_ITEM', { item: t('LOCATION') })
  const editText = t('EDIT_ITEM', { item: t('LOCATION') })

  const errorMessage = useErrorMessage()

  const locationPosition = (e: React.MouseEvent<HTMLImageElement>) => {
    const imgElement = e.target as HTMLImageElement
    const rect = imgElement.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top
    setFloorPlanX(x)
    setFloorPlanY(y)
  }

  useEffect(() => {
    const updatePosition = () => {
      const imgElement = document.querySelector(
        '#floor-plan-image',
      ) as HTMLImageElement
      const containerElement = document.querySelector(
        '#floor-plan-container',
      ) as HTMLImageElement

      if (!imgElement || !containerElement) {
        // The elements do not exist, so return early
        return
      }

      const imgRect = imgElement.getBoundingClientRect()
      const containerRect = containerElement.getBoundingClientRect()

      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft

      const relativeTop =
        imgRect.top + scrollTop - (containerRect.top + scrollTop)
      const relativeLeft =
        imgRect.left + scrollLeft - (containerRect.left + scrollLeft)

      setFloorPlanTop(relativeTop)
      setFloorPlanLeft(relativeLeft)
    }

    if (floorPlanX !== null && floorPlanY !== null && imageLoaded) {
      updatePosition()
      window.addEventListener('resize', updatePosition)

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updatePosition)
      }
    }
  }, [floorPlanX, floorPlanY, imageLoaded])

  const setFloorPlanPosition = async () => {
    if (locationId.current) {
      const patchData: LocationPatchItem = {
        id: locationId.current,
        floorPlanX,
        floorPlanY,
      }
      await patchLocation(patchData)
        .unwrap()
        .then(() => {
          cancelFloorPlan()
        })
        .catch(() => {})
    }
  }

  const resetFloorPlanPosition = () => {
    setFloorPlanX(null)
    setFloorPlanY(null)
  }

  const cancelFloorPlan = () => {
    setOpenFloorPlan(false)
    setImageLoaded(false)
    resetFloorPlanPosition()
  }

  return (
    <>
      <PageTitle
        title={
          areaItem !== null && areaItem.current
            ? `${t('LOCATIONS')} - ${getName(areaItem.current.areaName)} (${
                areaItem.current.areaCode
              })`
            : t('LOCATIONS')
        }
      />

      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <RoundButton
            onClick={handleOpenNewData}
            disabled={areaItem.current === null}
          >
            {newText}
          </RoundButton>
          <RoundButton
            onClick={handleBack}
            color="info"
            disabled={areaItem.current === null}
          >
            {t('BACK_TO_PREVIOUS_PAGE')}
          </RoundButton>
        </Box>
        <RoundButton onClick={generateQrCode} disabled={isLoadingQr}>
          {t('GENERATE_QR_CODE')}
        </RoundButton>
      </Box>

      {isSuccessNew && (
        <AlertMessage severity="success">
          {t('CREATE_NEW_ITEM_SUCCESS', { item: t('LOCATION') })}
        </AlertMessage>
      )}

      {isSuccessEdit && (
        <AlertMessage severity="success">
          {t('EDIT_ITEM_SUCCESS', { item: t('LOCATION') })}
        </AlertMessage>
      )}

      {isSuccessDelete && (
        <AlertMessage severity="success">
          {t('DELETE_ITEM_SUCCESS', { item: t('LOCATION') })}
        </AlertMessage>
      )}

      {isErrorDelete && (
        <AlertMessage severity="error">
          {errorMessage('delete', 'LOCATION', errorDelete)}
        </AlertMessage>
      )}

      <DataGrid
        {...dataGridProps}
        rows={listData || []}
        columns={columns}
        loading={isLoadingNew || isLoadingEdit || isLoadingDelete || isFetching}
        sx={{ backgroundColor: 'white', boxShadow: 3 }}
      />
      <NewEditData
        title={dataType === 'create' ? newText : editText}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isOpen={openData}
        onClose={handleCloseData}
        confirmText={dataType === 'create' ? newText : editText}
        isLoading={isLoading}
      >
        <>
          {isErrorNew && (
            <AlertMessage severity="error">
              {errorMessage('create', 'LOCATION', errorNew)}
            </AlertMessage>
          )}

          {isErrorEdit && (
            <AlertMessage severity="error">
              {errorMessage('edit', 'LOCATION', errorEdit)}
            </AlertMessage>
          )}

          <FormTextField
            name="locationCode"
            label={t('LOCATION_CODE')}
            disabled={dataType === 'edit'}
            control={control}
            error={errors.locationCode}
          />
          <FormTextField
            name="locationName.en"
            label={t('LOCATION_NAME_LOCALE', { locale: t('EN') })}
            control={control}
            error={errors.locationName?.en}
          />
          <FormTextField
            name="locationName.zhHk"
            label={t('LOCATION_NAME_LOCALE', { locale: t('ZH_HK') })}
            control={control}
            error={errors.locationName?.zhHk}
          />
          <FormTextField
            name="locationName.zhCn"
            label={t('LOCATION_NAME_LOCALE', { locale: t('ZH_CN') })}
            control={control}
            error={errors.locationName?.zhCn}
          />
          <FormSwitch
            name="testMode"
            label={t('TEST_MODE')}
            control={control}
          />
        </>
      </NewEditData>
      <Modal
        size="xl"
        isOpen={openFloorPlan && isSuccessFloorPlan}
        onClose={cancelFloorPlan}
        confirm={setFloorPlanPosition}
        confirmText={t('SAVE')}
        resetButton={floorPlanX !== null && floorPlanY !== null}
        onReset={resetFloorPlanPosition}
      >
        {areaItem.current && (
          <>
            <CenterBox
              id="floor-plan-container"
              sx={{
                position: 'relative',
              }}
            >
              {floorPlanY !== null &&
                floorPlanX !== null &&
                floorPlanTop !== null &&
                floorPlanLeft !== null && (
                  <CircleTwoToneIcon
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: floorPlanY + floorPlanTop - 10,
                      left: floorPlanX + floorPlanLeft - 10,
                      zIndex: 10,
                    }}
                    fontSize="small"
                  />
                )}
              <img
                id="floor-plan-image"
                src={floorPlan?.url}
                alt="Floor plan"
                onLoad={() => setImageLoaded(true)}
                onMouseDown={(e) => locationPosition(e)}
                style={{ cursor: 'crosshair' }}
              />
            </CenterBox>
            {isErrorEdit && (
              <AlertMessage severity="error">
                {errorMessage('edit', 'LOCATION', errorEdit)}
              </AlertMessage>
            )}
          </>
        )}
      </Modal>
      <Modal
        size="sm"
        isOpen={qrModal}
        closeText={t('CLOSE')}
        confirm={downloadQr}
        confirmText={t('DOWNLOAD_QR_CODE')}
        onClose={handleCloseQrModal}
      >
        <>
          <Typography variant="h3">{t('GENERATE_QR_CODE')}</Typography>
          {isSuccessQr && createQrRes && (
            <>
              <Typography sx={{ mb: 2 }}>
                {Object.keys(checkedItems).map((key) => {
                  if (checkedItems[key]) {
                    return `${key} `
                  }
                })}
              </Typography>
              <QRCode id="qr-code" value={createQrRes.token} />
            </>
          )}
          {isErrorQr && (
            <AlertMessage severity="error">
              {t('ERROR_GENERATE_QR_CODE')}
            </AlertMessage>
          )}
        </>
      </Modal>
    </>
  )
}
