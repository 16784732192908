/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useLocaleName from '../hooks/useLocaleName'
import useErrorMessage from '../hooks/useErrorMessage'
import useDataGridOptions from '../hooks/useDataGridOptions'
import useUserDetails from '../hooks/useUserDetails'
import RoundButton from '../styles/RoundButton.styled'
import AlertMessage from '../styles/AlertMessage.styled'
import {
  useLazyGetUsersQuery,
  useLazyGetUserDetailsQuery,
  usePostUserMutation,
  usePatchUserMutation,
  useDeleteUserMutation,
} from '../redux/userApi'
import { useLazyGetOrganizationsQuery } from '../redux/organizationApi'
import { GridEditAction, GridDeleteAction } from '../components/GridActions'
import NewEditData from '../components/NewEditData'
import {
  FormTextField,
  FormAutoComplete,
  FormPullDown,
  FormSwitch,
} from '../components/FormController'
import SearchFilter from '../components/SearchFilter'
import PageTitle from '../components/PageTitle'
import { UserFormItem, UserPatchItem, UserQueryParams } from '../types/user'
import { DataType } from '../types/global'

export default function Users() {
  const { t } = useTranslation()
  const { dataGridProps } = useDataGridOptions()
  const { isSystemAdmin } = useUserDetails()

  const userRoleList = [
    { value: 'system', label: t('SYSTEM_ADMIN') },
    { value: 'manager', label: t('MANAGER') },
    { value: 'staff', label: t('STAFF') },
  ]

  const getName = useLocaleName()

  const [openData, setOpenData] = useState(false)
  const [dataType, setDataType] = useState<DataType>('create')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    triggerGetUsers({})
    if (isSystemAdmin) {
      triggerOrganizations()
    }
  }, [])

  // Close the form dialog
  const handleCloseData = () => {
    setIsLoading(false)
    setOpenData(false)
  }

  useEffect(() => {
    if (!openData) {
      resetForm()
    }
  }, [openData])

  // Open the form dialog
  const handleOpenData = () => {
    resetAll()
    setIsLoading(false)
    setOpenData(true)
  }

  const handleOpenNewData = () => {
    setDataType('create')
    handleOpenData()
  }

  const handleEditData = () => {
    setDataType('edit')
    handleOpenData()
  }

  // Get the list of organizations from the API
  const [triggerOrganizations, { data: organizationData }] =
    useLazyGetOrganizationsQuery()

  const organizationItems = organizationData?.map((item) => ({
    value: item.id,
    label: getName(item.organizationName),
  }))

  const organizationSearchItems = organizationData?.map((item) => ({
    value: item.organizationCode,
    label: getName(item.organizationName),
  }))

  // Get the list of users from the API
  const [triggerGetUsers, { data: listData, isFetching }] =
    useLazyGetUsersQuery()

  // Get the list of users from the API
  const [triggerDetails] = useLazyGetUserDetailsQuery()

  // Create user mutation
  const [
    postUser,
    {
      isSuccess: isSuccessNew,
      isLoading: isLoadingNew,
      isError: isErrorNew,
      error: errorNew,
      reset: resetNew,
    },
  ] = usePostUserMutation()

  // Edit user mutation
  const [
    patchUser,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = usePatchUserMutation()

  // Delete user mutation
  const [
    deleteUser,
    {
      isSuccess: isSuccessDelete,
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: errorDelete,
      reset: resetDelete,
    },
  ] = useDeleteUserMutation()

  // Reset the mutation states
  const resetAll = () => {
    setIsLoading(true)
    resetNew()
    resetEdit()
    resetDelete()
  }

  // Schema of the create and edit form
  const schema = yup.object().shape({
    id: yup.string().optional(),
    username: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('USERNAME') })),
    password: yup.string().when('$dataType', {
      is: () => dataType === 'create',
      then: (schema) =>
        schema.required(t('ERROR_INPUT_REQUIRED', { field: t('PASSWORD') })),
      otherwise: (schema) => schema.optional(),
    }),
    confirmPassword: yup
      .string()
      .when('$dataType', {
        is: () => dataType === 'create',
        then: (schema) =>
          schema.required(t('ERROR_INPUT_REQUIRED', { field: t('PASSWORD') })),
        otherwise: (schema) => schema.optional(),
      })
      .oneOf([yup.ref('password')], t('ERROR_PASSWORD_MISMATCH')),
    email: yup
      .string()
      .email(t('ERROR_INVALID_EMAIL'))
      .required(t('ERROR_INPUT_REQUIRED', { field: t('EMAIL') })),
    userRole: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('USER_ROLE') })),
    organizationId: yup.string().when('userRole', {
      is: (val: string) => val === 'system' || !isSystemAdmin,
      then: (schema) => schema.optional(),
      otherwise: (schema) =>
        schema.required(
          t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION') }),
        ),
    }),
    enabled: yup
      .boolean()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('ENABLED') })),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset: resetForm,
  } = useForm<UserFormItem>({
    defaultValues: {
      id: '',
      username: '',
      password: '',
      confirmPassword: '',
      email: '',
      userRole: '',
      organizationId: '',
      enabled: true,
    },
    resolver: yupResolver(schema), // use yup to validate form values
  })

  // Schema of the search filter form
  const searchSchema = yup.object().shape({
    username: yup
      .string()
      .test(
        'minLength',
        t('ERROR_INPUT_MIN_LENGTH', { field: t('USERNAME'), min: 2 }),
        (val) => {
          return !val || val.length >= 2
        },
      ),
    user_role: yup.string().optional(),
    organization: yup.string().optional().nullable(),
  })

  // Search filter React hook form setup
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    formState: { errors: errorsSearch },
    watch: watchSearch,
    reset: resetSearch,
  } = useForm<UserQueryParams>({
    defaultValues: {
      username: '',
      user_role: '',
      organization: '',
    },
    resolver: yupResolver(searchSchema), // use yup to validate form values
  })

  // Form submit search data handler
  const onSubmitSearch: SubmitHandler<UserQueryParams> = async (data) => {
    // Remove the empty fields
    const filteredData: UserQueryParams = Object.fromEntries(
      Object.entries(data).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) => value !== '' && value !== null,
      ),
    ) as UserQueryParams
    await triggerGetUsers(filteredData)
  }

  const resetSearchFilter = async () => {
    resetSearch()
    await triggerGetUsers({})
  }

  // Define the columns of the data grid
  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: t('USERNAME'),
      flex: 2,
    },
    {
      field: 'userRole',
      headerName: t('USER_ROLE'),
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row.userRole === 'system'
              ? t('SYSTEM_ADMIN')
              : t(params.row.userRole.toUpperCase())}
          </>
        )
      },
    },
    {
      field: 'organizationName',
      headerName: t('ORGANIZATION_NAME'),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        if (params.row.organizationName !== undefined) {
          return <>{getName(params.row.organizationName)}</>
        } else {
          return <></>
        }
      },
    },
    {
      field: 'enabled',
      headerName: t('ENABLED'),
      flex: 1,
      renderCell: (params) => t(params.row.enabled ? 'TRUE' : 'FALSE'),
    },
    {
      field: 'actions',
      headerName: t('ACTIONS'),
      flex: 1,
      type: 'actions',
      getActions: (params) => {
        const onEdit = () => {
          resetAll()
          triggerDetails(params.id.toString(), false)
            .unwrap()
            .then((data) => {
              setValue('id', data.id)
              setValue('username', data.username)
              setValue('email', data.email)
              setValue('userRole', data.userRole)
              setValue('organizationId', data.organizationId || '')
              setValue('enabled', data.enabled)
              handleEditData()
            })
            .catch(() => {})
        }
        const onDelete = async () => {
          resetAll()
          await deleteUser(params.id.toString())
        }
        return [
          <GridEditAction key="edit" onEdit={onEdit} />,
          <GridDeleteAction
            key="delete"
            action={t('USER')}
            name={params.row.username}
            onDelete={onDelete}
          />,
        ]
      },
    },
  ]

  // Refresh the data grid when an user is deleted
  useEffect(() => {
    if (isSuccessNew || isSuccessEdit || isSuccessDelete) {
      const watchData = watchSearch()
      onSubmitSearch(watchData)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessNew, isSuccessEdit, isSuccessDelete])

  useEffect(() => {
    if (isErrorNew || isErrorEdit || isErrorDelete) {
      setIsLoading(false)
    }
  }, [isErrorNew, isErrorEdit, isErrorDelete])

  // Form submit data handler
  const onSubmit: SubmitHandler<UserFormItem> = async (data) => {
    resetAll()
    if (dataType === 'create') {
      // Create new user
      const postData = {
        username: data.username,
        password: data.password as string,
        email: data.email,
        userRole: data.userRole,
        organizationId: data.organizationId,
        enabled: data.enabled,
      }
      if (data.userRole === 'system' || !isSystemAdmin) {
        delete postData.organizationId
      }
      await postUser(postData)
        .unwrap()
        .then(() => {
          handleCloseData()
        })
        .catch(() => {})
    } else {
      // Edit user
      if (data.id !== undefined) {
        const patchData: UserPatchItem = {
          id: data.id,
          username: data.username,
          email: data.email,
          password: data.password === '' ? undefined : data.password,
          userRole: data.userRole,
          enabled: data.enabled,
        }
        await patchUser(patchData)
          .unwrap()
          .then(() => {
            handleCloseData()
          })
          .catch(() => {})
      }
    }
  }

  const newOrgText = t('CREATE_NEW_ITEM', { item: t('USER') })
  const editOrgText = t('EDIT_ITEM', { item: t('USER') })

  const errorMessage = useErrorMessage()

  return (
    <>
      <PageTitle title={t('USERS')} />

      <RoundButton sx={{ mb: 2 }} onClick={handleOpenNewData}>
        {newOrgText}
      </RoundButton>

      {isSuccessNew && (
        <AlertMessage severity="success">
          {t('CREATE_NEW_ITEM_SUCCESS', { item: t('USER') })}
        </AlertMessage>
      )}

      {isSuccessEdit && (
        <AlertMessage severity="success">
          {t('EDIT_ITEM_SUCCESS', { item: t('USER') })}
        </AlertMessage>
      )}

      {isSuccessDelete && (
        <AlertMessage severity="success">
          {t('DELETE_ITEM_SUCCESS', { item: t('USER') })}
        </AlertMessage>
      )}

      {isErrorDelete && (
        <AlertMessage severity="error">
          {errorMessage('delete', 'USER', errorDelete)}
        </AlertMessage>
      )}

      <SearchFilter
        onSubmit={onSubmitSearch}
        handleSubmit={handleSubmitSearch}
        onReset={resetSearchFilter}
      >
        <Grid item xs={12} md={2}>
          <FormTextField
            name="username"
            label={t('USERNAME')}
            control={controlSearch}
            error={errorsSearch.username}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormPullDown
            name="user_role"
            label={t('USER_ROLE')}
            control={controlSearch}
            error={errorsSearch.user_role}
            items={isSystemAdmin ? userRoleList : userRoleList.slice(1)}
          />
        </Grid>
        {isSystemAdmin && (
          <Grid item xs={12} md={2}>
            <FormAutoComplete
              name="organization"
              label={t('ORGANIZATION')}
              control={controlSearch}
              error={errorsSearch.organization}
              items={organizationSearchItems || []}
            />
          </Grid>
        )}
      </SearchFilter>

      <DataGrid
        {...dataGridProps}
        rows={listData || []}
        columns={columns}
        loading={isLoadingNew || isLoadingEdit || isLoadingDelete || isFetching}
        sx={{ backgroundColor: 'white', boxShadow: 3 }}
      />
      <NewEditData
        title={dataType === 'create' ? newOrgText : editOrgText}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isOpen={openData}
        onClose={handleCloseData}
        confirmText={dataType === 'create' ? newOrgText : editOrgText}
        isLoading={isLoading}
      >
        <>
          {isErrorNew && (
            <AlertMessage severity="error">
              {errorMessage('create', 'USER', errorNew)}
            </AlertMessage>
          )}

          {isErrorEdit && (
            <AlertMessage severity="error">
              {errorMessage('edit', 'USER', errorEdit)}
            </AlertMessage>
          )}

          <FormTextField
            name="username"
            label={t('USERNAME')}
            control={control}
            error={errors.username}
          />
          <FormTextField
            name="password"
            label={dataType === 'edit' ? t('NEW_PASSWORD') : t('PASSWORD')}
            isPassword
            control={control}
            error={errors.password}
          />
          <FormTextField
            name="confirmPassword"
            label={t('CONFIRM_PASSWORD')}
            isPassword
            control={control}
            error={errors.confirmPassword}
          />
          <FormTextField
            name="email"
            label={t('EMAIL')}
            control={control}
            error={errors.email}
          />
          <FormPullDown
            name="userRole"
            label={t('USER_ROLE')}
            disabled={dataType === 'edit' && getValues('userRole') === 'system'}
            control={control}
            error={errors.userRole}
            items={
              isSystemAdmin
                ? watch('organizationId') === '' ||
                  watch('organizationId') === null
                  ? userRoleList
                  : userRoleList.slice(1)
                : userRoleList.slice(1)
            }
          />
          {isSystemAdmin && (
            <FormAutoComplete
              name="organizationId"
              label={t('ORGANIZATION')}
              disabled={
                dataType === 'edit' ||
                watch('userRole') === 'system' ||
                !isSystemAdmin
              }
              control={control}
              error={errors.organizationId}
              items={organizationItems || []}
            />
          )}
          <FormSwitch name="enabled" label={t('ENABLED')} control={control} />
        </>
      </NewEditData>
    </>
  )
}
