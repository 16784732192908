import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CenterBox from '../styles/CenterBox.styled'

export default function NoResult({
  forbidden = false,
}: {
  forbidden?: boolean
}) {
  const { t } = useTranslation()
  return (
    <CenterBox sx={{ height: '100%' }}>
      <Typography variant="h5" component="p" py={3}>
        {forbidden ? t('ERROR_FORBIDDEN') : t('NO_RESULTS')}
      </Typography>
    </CenterBox>
  )
}
