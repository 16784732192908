import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import {
  OrganizationsList,
  PostOrganizationRequest,
  // OrganizationDetailsItem,
  PatchOrganizationRequest,
  GetOrganizationDetailsResponse,
  // PostPatchOrganizationRequest,
} from '../types/organization'

export const organizationApi = createApi({
  baseQuery,
  reducerPath: 'organizationApi',
  endpoints: (build) => ({
    getOrganizations: build.query<OrganizationsList, void>({
      query: () => ({
        url: '/organizations',
        method: 'GET',
      }),
    }),
    getOrganizationDetails: build.query<GetOrganizationDetailsResponse, string>(
      {
        query: (id) => ({
          url: `/organizations/${id}`,
          method: 'GET',
        }),
      },
    ),
    postOrganization: build.mutation<
      GetOrganizationDetailsResponse,
      PostOrganizationRequest
    >({
      query: (body) => ({
        url: '/organizations',
        method: 'POST',
        body,
      }),
    }),
    patchOrganization: build.mutation<
      GetOrganizationDetailsResponse,
      PatchOrganizationRequest
    >({
      query: ({ id, body }) => {
        return {
          url: `/organizations/${id}`,
          method: 'PATCH',
          body,
        }
      },
    }),
    deleteOrganization: build.mutation<void, string>({
      query: (id) => ({
        url: `/organizations/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useGetOrganizationDetailsQuery,
  useLazyGetOrganizationDetailsQuery,
  usePostOrganizationMutation,
  usePatchOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationApi
