import React from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LandingLayout from '../layouts/LandingLayout'
import PageTitle from '../components/PageTitle'

export default function PageError() {
  const { t } = useTranslation()
  return (
    <LandingLayout>
      <PageTitle title={t('ERROR_PAGE_NOT_FOUND')} noTitle={true} />
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">Page not found</Typography>
      <Typography variant="h4">找不到頁面</Typography>
      <Typography variant="h4">找不到页面</Typography>
    </LandingLayout>
  )
}
