/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { mainMenu, systemAdminMenu, managerMenu } from '../components/MenuItems'
import PageTitle from '../components/PageTitle'
import useUserDetails from '../hooks/useUserDetails'
import HomeButton from '../styles/HomeButton.styled'
import { useLazyGetDashboardQuery } from '../redux/healthApi'

function DashboardButton({
  text,
  icon,
  path,
}: {
  text: string
  icon: JSX.Element
  path: string
}) {
  const navigate = useNavigate()
  const handleClick = (): void => {
    navigate(path)
  }
  return (
    <HomeButton
      fullWidth
      size="large"
      startIcon={icon}
      onClick={handleClick}
      color="primary"
    >
      {text}
    </HomeButton>
  )
}

// Skip the first item in the mainMenu array
const DashboardMenu = mainMenu.slice(1)

export default function Dashboard() {
  const { t } = useTranslation()

  const { isSystemAdmin, isManager } = useUserDetails()
  const [healthCheck] = useLazyGetDashboardQuery()

  // Trigger the dashboard health check
  useEffect(() => {
    healthCheck()
  }, [])

  return (
    <>
      <PageTitle title={t('DASHBOARD')} />
      <Grid container spacing={2}>
        {DashboardMenu.map((item) => (
          <Grid item xs={12} md={3} key={item.name}>
            <DashboardButton
              text={t(item.name)}
              icon={item.icon}
              path={item.path}
            />
          </Grid>
        ))}
      </Grid>
      {isSystemAdmin && (
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {systemAdminMenu.map((item) => (
            <Grid item xs={12} md={3} key={item.name}>
              <DashboardButton
                text={t(item.name)}
                icon={item.icon}
                path={item.path}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {isManager && (
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          {managerMenu.map((item) => (
            <Grid item xs={12} md={4} key={item.name}>
              <DashboardButton
                text={t(item.name)}
                icon={item.icon}
                path={item.path}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  )
}
