import { useTranslation } from 'react-i18next'
import { LocaleNames } from '../types/global'

export default function useLocaleName() {
  const { i18n } = useTranslation()
  const language = i18n.language

  return (name: LocaleNames) => {
    if (name) {
      if (language === 'zh-HK') {
        return name.zhHk
      } else if (language === 'zh-CN') {
        return name.zhCn
      } else {
        return name.en
      }
    } else {
      return ''
    }
  }
}
