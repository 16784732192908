import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import { Box, Button, Menu, MenuItem } from '@mui/material'

export default function LanguageSwitcher() {
  const { i18n } = useTranslation()

  // get current language from i18n
  const currentLanguage = i18n.language

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeLanguage = (locale: string) => {
    i18n.changeLanguage(locale).then(() => {
      handleClose()
    })
  }

  const [language, setLanguage] = useState('EN')

  useEffect(() => {
    if (currentLanguage === 'zh-HK') {
      setLanguage('繁')
    } else if (currentLanguage === 'zh-CN') {
      setLanguage('简')
    } else {
      setLanguage('EN')
    }
  }, [currentLanguage])

  return (
    <>
      <Box>
        <Button
          startIcon={<LanguageRoundedIcon />}
          id="language"
          variant="text"
          aria-label="language"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpen}
          color="info"
        >
          {language}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        color="info"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language',
        }}
      >
        <MenuItem
          onClick={() => changeLanguage('en')}
          selected={currentLanguage === 'en'}
        >
          English
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage('zh-HK')}
          selected={currentLanguage === 'zh-HK'}
        >
          繁體中文
        </MenuItem>
        <MenuItem
          onClick={() => changeLanguage('zh-CN')}
          selected={currentLanguage === 'zh-CN'}
        >
          简体中文
        </MenuItem>
      </Menu>
    </>
  )
}
