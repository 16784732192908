/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Snackbar, Alert } from '@mui/material'

import { usePatchOrganizationMutation } from '../../redux/organizationApi'

import AlertMessage from '../../styles/AlertMessage.styled'
import NewEditData from '../../components/NewEditData'
import useErrorMessage from '../../hooks/useErrorMessage'

import { FormTextField } from '../../components/FormController'

import type {
  OrganizationNtagConfigForm,
  OrganizationNtagConfig,
  GetOrganizationDetailsResponse,
} from '../../types/organization'

interface ConfigNtagProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  details?: GetOrganizationDetailsResponse
}

export default function ConfigNtag({
  isOpen,
  setIsOpen,
  details,
}: ConfigNtagProps) {
  const { t } = useTranslation()
  const errorMessage = useErrorMessage()

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)

  const [patchOrg, { isLoading, isSuccess, isError, error }] =
    usePatchOrganizationMutation()

  const schema = yup.object().shape({
    key0: yup.string(),
    key1: yup.string(),
    key2: yup.string(),
    key3: yup.string(),
    key4: yup.string(),
    sdmMetaRead: yup.string(),
    sdmFileRead: yup.string(),
    change: yup.string(),
  })
  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm<OrganizationNtagConfigForm>({
    defaultValues: {
      key0: '',
      key1: '',
      key2: '',
      key3: '',
      key4: '',
      sdmMetaRead: '',
      sdmFileRead: '',
      change: '',
    },
    resolver: yupResolver(schema), // use yup to validate form values
  })

  const onSubmit: SubmitHandler<OrganizationNtagConfigForm> = async (data) => {
    //resetAll()
    try {
      const ntag: OrganizationNtagConfig = {}

      const id = details?.id

      if (!id) return

      Object.keys(data).forEach((key) => {
        const dataField = data[key as keyof OrganizationNtagConfigForm]
        if (key.startsWith('key') && dataField) {
          ntag.keys = {
            ...ntag.keys,
            [key]: dataField,
          }
        } else if ((key.startsWith('sdm') || key === 'change') && dataField) {
          ntag.access = {
            ...ntag.access,
            [key]: dataField,
          }
        }
      })

      if (!ntag.keys && !ntag.access) return

      patchOrg({ id, body: { ntag } })
    } catch (_) {
      /* empty */
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    resetForm()
  }

  // Set form values when details are fetched
  useEffect(() => {
    if (!details && isOpen) {
      setIsOpen(false)
      return
    }

    const access = details?.ntag?.access
    if (access) {
      setValue('sdmMetaRead', access.sdmMetaRead ?? '')
      setValue('sdmFileRead', access.sdmFileRead ?? '')
      setValue('change', access.change ?? '')
    }
  }, [details])

  // Close the dialog when the patch is successful
  useEffect(() => {
    if (isSuccess) {
      setOpenSnackbar(true)
      handleClose()
    }
  }, [isSuccess])

  return (
    <>
      <NewEditData
        title={t('NTAG')}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isOpen={isOpen}
        onClose={handleClose}
        confirmText={t('CONFIRM')}
        isLoading={isLoading}
      >
        <>
          {isError && (
            <AlertMessage severity="error">
              {errorMessage('edit', 'NTAG', error)}
            </AlertMessage>
          )}

          <FormTextField
            name="key0"
            label={t('NTAG_MASTER_KEY')}
            control={control}
            error={errors.key0}
          />
          <FormTextField
            name="key1"
            label={t('NTAG_KEY_NAME', { key: '1' })}
            control={control}
            error={errors.key1}
          />
          <FormTextField
            name="key2"
            label={t('NTAG_KEY_NAME', { key: '2' })}
            control={control}
            error={errors.key2}
          />
          <FormTextField
            name="key3"
            label={t('NTAG_KEY_NAME', { key: '3' })}
            control={control}
            error={errors.key3}
          />
          <FormTextField
            name="key4"
            label={t('NTAG_KEY_NAME', { key: '4' })}
            control={control}
            error={errors.key4}
          />
          <FormTextField
            name="sdmMetaRead"
            label={t('SDM_META_READ')}
            control={control}
            error={errors.sdmMetaRead}
          />
          <FormTextField
            name="sdmFileRead"
            label={t('SDM_FILE_READ')}
            control={control}
            error={errors.sdmFileRead}
          />
          <FormTextField
            name="change"
            label={t('NTAG_CHANGE')}
            control={control}
            error={errors.change}
          />
        </>
      </NewEditData>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={null}
        transitionDuration={0}
      >
        <Alert severity="success" onClose={() => setOpenSnackbar(false)}>
          {t('NTAG_CONFIG_SUCCESS')}
        </Alert>
      </Snackbar>
    </>
  )
}
