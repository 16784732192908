/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  TextField,
  MenuItem,
  Autocomplete,
  Switch,
  FormControlLabel,
} from '@mui/material'
import {
  Controller,
  Control,
  FieldError,
  FieldValues,
  FieldPath,
} from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-hk'
import 'dayjs/locale/zh-cn'
import { OptionItems } from '../types/global'

interface FormControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> {
  name: FieldPath<TFieldValues>
  label: string
  disabled?: boolean
  control: Control<TFieldValues, TContext>
}

interface FormWithErrorProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends FormControllerProps<TFieldValues, TContext> {
  error: FieldError | undefined
}

interface FormTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends FormWithErrorProps<TFieldValues, TContext> {
  isPassword?: boolean
  multiline?: boolean
  row?: number
  mb?: number
}

interface FormPullDownProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends FormWithErrorProps<TFieldValues, TContext> {
  items: OptionItems
  multiple?: boolean
  groupLabel?: OptionItems
}

interface FormDatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends FormWithErrorProps<TFieldValues, TContext> {
  minDate?: dayjs.Dayjs
  maxDate?: dayjs.Dayjs
}

export function FormTextField<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>({
  name,
  label,
  disabled = false,
  control,
  error,
  isPassword = false,
  multiline = false,
  row = 1,
  mb = 2,
}: FormTextFieldProps<TFieldValues, TContext>) {
  return (
    <Controller
      key={name.toString()}
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          autoComplete="off"
          label={label}
          type={isPassword ? 'password' : 'text'}
          disabled={disabled}
          multiline={multiline}
          rows={row}
          error={!!error}
          helperText={error?.message}
          sx={{ mb }}
        />
      )}
    />
  )
}

export function FormPullDown<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>({
  name,
  label,
  disabled = false,
  control,
  error,
  items,
  multiple = false,
}: FormPullDownProps<TFieldValues, TContext>) {
  return (
    <Controller
      key={name.toString()}
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          select
          autoComplete="off"
          label={label}
          disabled={disabled}
          error={!!error}
          helperText={error?.message}
          sx={{ mb: 2 }}
          SelectProps={{
            multiple,
          }}
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}

export function FormAutoComplete<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>({
  name,
  label,
  disabled = false,
  control,
  error,
  items,
  groupLabel,
  multiple = false,
}: FormPullDownProps<TFieldValues, TContext>) {
  const { t } = useTranslation()
  return (
    <Controller
      key={name.toString()}
      name={name}
      control={control}
      render={({ field: { ref, onChange, value, ...field } }) => (
        <Autocomplete
          multiple={multiple}
          disablePortal
          {...field}
          options={items.length > 0 ? items.map((item) => item.value) : []}
          value={value === '' ? null : value}
          getOptionLabel={(option) => {
            const item = items.find((item) => item.value === option)
            return item ? item.label : ''
          }}
          getOptionKey={(option) => {
            const item = items.find((item) => item.value === option)
            return item ? item.value : ''
          }}
          onChange={(event, data) => onChange(data)}
          disabled={disabled}
          noOptionsText={t('ERROR_AUTO_COMPLETE')}
          sx={{ mb: 2 }}
          {...(items &&
            items.length > 0 &&
            items[0].group &&
            groupLabel && {
              groupBy: (option) => {
                const item = items.find((item) => item.value === option)
                if (!(item && item.group)) {
                  return ''
                }
                const group = groupLabel.find(
                  (label) => label.value === item.group,
                )
                return group ? group.label : ''
              },
            })}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              inputRef={ref}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
      )}
    />
  )
}

export function FormSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>({
  name,
  label,
  disabled = false,
  control,
}: FormControllerProps<TFieldValues, TContext>) {
  return (
    <Controller
      key={name.toString()}
      name={name}
      control={control}
      render={({ field: { value, ...field } }) => (
        <FormControlLabel
          disabled={disabled}
          label={label}
          sx={{ mb: 2 }}
          control={<Switch {...field} checked={value} />}
        />
      )}
    />
  )
}

export function FormDatePicker<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>({
  name,
  label,
  disabled = false,
  control,
  error,
  minDate,
  maxDate,
}: FormDatePickerProps<TFieldValues, TContext>) {
  const { i18n } = useTranslation()
  const language = i18n.language
  return (
    <Controller
      key={name.toString()}
      name={name}
      control={control}
      render={({ field: { onChange, value, ...field } }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={
            language === 'zh-HK'
              ? 'zh-hk'
              : language === 'zh-CN'
                ? 'zh-cn'
                : 'en'
          }
        >
          <DatePicker
            disabled={disabled}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
            value={value ? dayjs(value) : null}
            onChange={(event) => onChange(event)}
            {...field}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!error,
                helperText: error?.message,
                sx: { mb: 2 },
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  )
}
