import { useTranslation } from 'react-i18next'
import { zhCN, zhTW, GridLocaleText } from '@mui/x-data-grid'
import NoResults from '../components/NoResults'
import { useTheme } from '@mui/material/styles'

export default function useDataGridLocaleText() {
  const { i18n } = useTranslation()
  const language = i18n.language
  const theme = useTheme()
  let localeText: Partial<GridLocaleText> = {}
  if (language === 'zh-HK') {
    localeText = zhTW.components.MuiDataGrid.defaultProps.localeText
  } else if (language === 'zh-CN') {
    localeText = zhCN.components.MuiDataGrid.defaultProps.localeText
  }

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: 20,
      },
    },
  }

  const pageSizeOptions = [20, 50, 100, 200]

  const slots = {
    noRowsOverlay: NoResults,
  }

  const dataGridProps = {
    autoHeight: true,
    disableColumnFilter: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
    disableRowSelectionOnClick: true,
    initialState,
    pageSizeOptions,
    slots,
    localeText,
    sx: { boxShadow: 3, backgroundColor: theme.palette.background.paper },
  }

  return {
    initialState,
    pageSizeOptions,
    slots,
    localeText,
    dataGridProps,
  }
}
