import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import type { GridProps } from '@mui/material'

const CenterGrid = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})) as typeof Grid

export default CenterGrid
