import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import {
  AreasList,
  AreaPostItem,
  AreaPatchItem,
  AreaDetailsItem,
  AreaQueryParams,
  AreaPostFloorPlan,
  FloorPlanURL,
} from '../types/area'

export const areaApi = createApi({
  baseQuery,
  reducerPath: 'areaApi',
  endpoints: (build) => ({
    getAreas: build.query<AreasList, AreaQueryParams>({
      query: (params) => ({
        url: '/areas',
        method: 'GET',
        params,
      }),
    }),
    getAreaDetails: build.query<AreaDetailsItem, string>({
      query: (id) => ({
        url: `/areas/${id}`,
        method: 'GET',
      }),
    }),
    postArea: build.mutation<AreaDetailsItem, AreaPostItem>({
      query: (body) => ({
        url: '/areas',
        method: 'POST',
        body,
      }),
    }),
    patchArea: build.mutation<AreaDetailsItem, AreaPatchItem>({
      query: (data) => {
        const { id, ...body } = data
        return {
          url: `/areas/${id}`,
          method: 'PATCH',
          body,
        }
      },
    }),
    deleteArea: build.mutation<void, string>({
      query: (id) => ({
        url: `/areas/${id}`,
        method: 'DELETE',
      }),
    }),
    getFloorPlan: build.query<FloorPlanURL, string>({
      query: (id) => ({
        url: `/areas/floor-plan/${id}`,
        method: 'GET',
      }),
    }),
    uploadFloorPlan: build.mutation<void, AreaPostFloorPlan>({
      query: (data) => {
        const { id, body } = data
        return {
          url: `/areas/floor-plan/${id}`,
          method: 'POST',
          body,
        }
      },
    }),
    deleteFloorPlan: build.mutation<void, string>({
      query: (id) => ({
        url: `/areas/floor-plan/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAreasQuery,
  useLazyGetAreasQuery,
  useLazyGetAreaDetailsQuery,
  usePostAreaMutation,
  usePatchAreaMutation,
  useDeleteAreaMutation,
  useLazyGetFloorPlanQuery,
  useUploadFloorPlanMutation,
  useDeleteFloorPlanMutation,
} = areaApi
