import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  fetchError: false,
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setFetchError: (state, action: PayloadAction<boolean>) => {
      state.fetchError = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFetchError } = generalSlice.actions

export default generalSlice.reducer
