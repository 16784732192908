import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
} from '@mui/material'
import RoundButton from '../styles/RoundButton.styled'
import { useTranslation } from 'react-i18next'

export default function Modal({
  content,
  // element,
  children,
  size = 'sm',
  deleteButton = false,
  isOpen,
  closeText = 'CANCEL',
  onClose,
  confirm,
  confirmText = 'CONFIRM',
  onlyClose = false,
  resetButton = false,
  onReset,
}: {
  content?: string
  // element?: JSX.Element | null
  children?: JSX.Element | null
  size?: 'sm' | 'md' | 'lg' | 'xl'
  deleteButton?: boolean
  isOpen: boolean
  closeText?: string
  onClose: () => void
  confirm?: () => void
  confirmText?: string
  onlyClose?: boolean
  resetButton?: boolean
  onReset?: () => void
}) {
  const { t } = useTranslation()
  return (
    <Dialog open={isOpen} maxWidth={size} fullWidth>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {/* {element} */}
        {children}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: resetButton ? 'space-between' : undefined,
            width: resetButton ? '100%' : undefined,
          }}
        >
          {resetButton && (
            <RoundButton onClick={onReset} color="secondary">
              {t('CLEAR_SIGNAL_MARKER')}
            </RoundButton>
          )}
          <Box>
            <RoundButton onClick={onClose} color="info">
              {t(onlyClose ? 'CLOSE' : closeText)}
            </RoundButton>
            {!onlyClose && (
              <RoundButton
                onClick={confirm}
                color={deleteButton ? 'error' : 'primary'}
                sx={{ marginLeft: 1 }}
              >
                {t(confirmText)}
              </RoundButton>
            )}
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
