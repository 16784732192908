import React from 'react'
import { MenuItem } from '../types/global'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined'

import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import NfcOutlinedIcon from '@mui/icons-material/NfcOutlined'

import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
//import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined'
import TapAndPlayOutlinedIcon from '@mui/icons-material/TapAndPlayOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'

export const mainMenu: MenuItem[] = [
  {
    path: '/dashboard',
    name: 'DASHBOARD',
    icon: <HomeOutlinedIcon />,
  },
  {
    path: '/alerts',
    name: 'ALERTS',
    icon: <AnnouncementOutlinedIcon />,
  },
  {
    path: '/signals',
    name: 'SIGNALS',
    icon: <TrafficOutlinedIcon />,
  },
  {
    path: '/statistics',
    name: 'STATISTICS',
    icon: <BarChartOutlinedIcon />,
  },
  {
    path: '/logs',
    name: 'LOGS',
    icon: <TextSnippetOutlinedIcon />,
  },
]

export const systemAdminMenu: MenuItem[] = [
  {
    path: '/organizations',
    name: 'ORGANIZATIONS',
    icon: <CorporateFareOutlinedIcon />,
  },
  {
    path: '/areas',
    name: 'AREAS',
    icon: <RoomOutlinedIcon />,
  },
  {
    path: '/users',
    name: 'USERS',
    icon: <GroupOutlinedIcon />,
  },
  {
    path: '/ntags',
    name: 'NTAGS',
    icon: <NfcOutlinedIcon />,
  },
]

export const managerMenu: MenuItem[] = [
  {
    path: '/users',
    name: 'USERS',
    icon: <GroupOutlinedIcon />,
  },
]

export const topMenu: MenuItem[] = [
  {
    path: '/notifications',
    name: 'NOTIFICATIONS',
    icon: <NotificationsOutlinedIcon />,
  },
  {
    path: '/nfc',
    name: 'NFC_READER',
    icon: <TapAndPlayOutlinedIcon />,
    isMobile: true,
  },
  {
    path: '/settings',
    name: 'SETTINGS',
    icon: <SettingsOutlinedIcon />,
  },
  {
    path: '/logout',
    name: 'LOGOUT',
    icon: <LogoutOutlinedIcon />,
  },
]
