import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useLocaleName from '../../hooks/useLocaleName'
import useDataGridOptions from '../../hooks/useDataGridOptions'
import useErrorMessage from '../../hooks/useErrorMessage'
import RoundButton from '../../styles/RoundButton.styled'
import AlertMessage from '../../styles/AlertMessage.styled'
import {
  useGetOrganizationsQuery,
  useLazyGetOrganizationDetailsQuery,
  usePostOrganizationMutation,
  usePatchOrganizationMutation,
  useDeleteOrganizationMutation,
} from '../../redux/organizationApi'
import {
  GridEditAction,
  GridDeleteAction,
  GridConfigNtagAction,
} from '../../components/GridActions'
import NewEditData from '../../components/NewEditData'
import {
  FormTextField,
  FormPullDown,
  FormSwitch,
} from '../../components/FormController'
import PageTitle from '../../components/PageTitle'
import {
  OrganizationFormItem,
  PostPatchOrganizationRequest,
  PostOrganizationRequest,
} from '../../types/organization'
import { DataType } from '../../types/global'

import ConfigNtag from './ConfigNtag'

export default function Organizations() {
  const { t } = useTranslation()
  const { initialState, pageSizeOptions, slots, localeText } =
    useDataGridOptions()

  const getName = useLocaleName()

  const [openData, setOpenData] = useState<boolean>(false)
  const [dataType, setDataType] = useState<DataType>('create')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openConfigNtag, setOpenConfigNtag] = useState<boolean>(false)

  // Close the form dialog
  const handleCloseData = () => {
    setIsLoading(false)
    setOpenData(false)
  }

  useEffect(() => {
    if (!openData) {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openData])

  // Open the form dialog
  const handleOpenData = () => {
    setIsLoading(false)
    setOpenData(true)
  }

  const handleOpenNewData = () => {
    resetAll()
    setDataType('create')
    handleOpenData()
  }

  const handleEditData = () => {
    setDataType('edit')
    handleOpenData()
  }

  // Get the list of organizations from the API
  const {
    data: listData,
    isFetching,
    refetch,
  } = useGetOrganizationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  // Get the list of organizations from the API
  const [getOrgDetails, { data: orgDetails, isSuccess: successOrgDetails }] =
    useLazyGetOrganizationDetailsQuery()

  // Create organization mutation
  const [
    postOrganization,
    {
      isSuccess: isSuccessNew,
      isLoading: isLoadingNew,
      isError: isErrorNew,
      error: errorNew,
      reset: resetNew,
    },
  ] = usePostOrganizationMutation()

  // Edit organization mutation
  const [
    patchOrganization,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = usePatchOrganizationMutation()

  // Delete organization mutation
  const [
    deleteOrganization,
    {
      isSuccess: isSuccessDelete,
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: errorDelete,
      reset: resetDelete,
    },
  ] = useDeleteOrganizationMutation()

  // Reset the mutation states
  const resetAll = () => {
    setIsLoading(true)
    resetNew()
    resetEdit()
    resetDelete()
  }

  // Schema of the login form
  const schema = yup.object().shape({
    organizationCode: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION_CODE') })),
    organizationName: yup.object().shape({
      en: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('ORGANIZATION_NAME_LOCALE', { locale: t('EN') }),
        }),
      ),
      zhHk: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('ORGANIZATION_NAME_LOCALE', { locale: t('ZH_HK') }),
        }),
      ),
      zhCn: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('ORGANIZATION_NAME_LOCALE', { locale: t('ZH_CN') }),
        }),
      ),
    }),
    media: yup.string().required(),
    mediaPath: yup.string().when('media', {
      is: (val: string) => val === 'local',
      then: (schema) =>
        schema.required(
          t('ERROR_INPUT_REQUIRED', { field: t('MEDIA_STORAGE') }),
        ),
      otherwise: (schema) => schema.optional(),
    }),
    apiKey: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('API_KEY') })),
    login: yup.string().required(),
    whitelist: yup
      .string()
      .optional()
      .matches(
        /^((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:,\s*)?)*$/,
        t('ERROR_IP_ADDRESS'),
      ),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset: resetForm,
  } = useForm<OrganizationFormItem>({
    defaultValues: {
      organizationCode: '',
      organizationName: {
        en: '',
        zhHk: '',
        zhCn: '',
      },
      media: 'none',
      mediaPath: '',
      apiKey: '',
      login: 'user',
      whitelist: '',
      ntag: false,
    },
    resolver: yupResolver(schema), // use yup to validate form values
  })

  // Define the columns of the data grid
  const columns: GridColDef[] = [
    {
      field: 'organizationCode',
      headerName: t('ORGANIZATION_CODE'),
      flex: 2,
    },
    {
      field: 'organizationName',
      headerName: t('ORGANIZATION_NAME'),
      flex: 2,
      sortable: false,
      valueFormatter: (params) => {
        return getName(params.value)
      },
    },
    {
      field: 'media',
      headerName: t('MEDIA_SUPPORT'),
      flex: 1,
      valueFormatter: (params) => {
        return t(params.value.toUpperCase())
      },
    },
    {
      field: 'login',
      headerName: t('LOGIN_TYPE'),
      flex: 1,
      valueFormatter: (params) => {
        return t(params.value.toUpperCase() + '_LOGIN')
      },
    },
    {
      field: 'actions',
      headerName: t('ACTIONS'),
      flex: 1,
      type: 'actions',
      getActions: (params) => {
        const onEdit = () => {
          resetAll()
          getOrgDetails(params.id.toString(), false)
            .unwrap()
            .then((data) => {
              setValue('organizationCode', data.organizationCode)
              setValue('organizationName.en', data.organizationName.en)
              setValue('organizationName.zhHk', data.organizationName.zhHk)
              setValue('organizationName.zhCn', data.organizationName.zhCn)
              setValue('media', data.media)
              setValue('mediaPath', data.mediaPath)
              setValue('apiKey', data.apiKey ?? '')
              setValue('login', data.login)
              if (data.whitelist && data.whitelist.length > 0) {
                setValue('whitelist', data.whitelist.join(','))
              }
              if (data.ntag && data.ntag.enabled) {
                setValue('ntag', true)
              }
              handleEditData()
            })
            .catch(() => {})
        }
        const onConfigNtag = async () => {
          const getDetails = await getOrgDetails(params.id.toString()).unwrap()
          if (getDetails) {
            //console.log('getDetails', getDetails)
            setOpenConfigNtag(true)
          }
        }
        const onDelete = async () => {
          resetAll()
          await deleteOrganization(params.id.toString())
        }
        return [
          <GridEditAction key="edit" onEdit={onEdit} />,
          <GridConfigNtagAction
            key="config"
            onClick={onConfigNtag}
            disabled={!params.row.ntag}
          />,
          <GridDeleteAction
            key="delete"
            action={t('ORGANIZATION')}
            name={getName(params.row.organizationName)}
            onDelete={onDelete}
          />,
        ]
      },
    },
  ]

  // Refresh the data grid when a organization is deleted
  useEffect(() => {
    if (isSuccessNew || isSuccessEdit || isSuccessDelete) {
      refetch()
      setIsLoading(false)
    }
  }, [isSuccessNew, isSuccessEdit, isSuccessDelete, refetch])

  useEffect(() => {
    if (isErrorNew || isErrorEdit || isErrorDelete) {
      setIsLoading(false)
    }
  }, [isErrorNew, isErrorEdit, isErrorDelete])

  // Convert the whitelist string to array
  const whitelistToArray = (ip?: string) => {
    const ipArray = ip ? ip.split(',') : []
    if (ipArray[ipArray.length - 1] === '') {
      ipArray.pop()
    }
    return ipArray
  }

  // Form submit data handler
  const onSubmit: SubmitHandler<OrganizationFormItem> = async (data) => {
    resetAll()
    const whitelist = whitelistToArray(data.whitelist)
    const postData: PostOrganizationRequest = {
      ...data,
      whitelist: whitelist,
      ntag: {
        enabled: data.ntag ?? false,
      },
    }
    if (data.media !== 'local') {
      delete postData.mediaPath
    }
    if (!data.ntag) {
      delete postData.ntag
    }
    await postOrganization(postData)
      .unwrap()
      .then(() => {
        handleCloseData()
      })
      .catch(() => {})
  }

  const onEditSubmit: SubmitHandler<OrganizationFormItem> = async (data) => {
    resetAll()
    const whitelist = whitelistToArray(data.whitelist)

    const patchDataBody: PostPatchOrganizationRequest = {
      organizationName: data.organizationName,
      media: data.media,
      apiKey: data.apiKey,
      login: data.login,
      whitelist: whitelist,
      ntag: {
        enabled: data.ntag,
      },
    }

    if (data.media === 'local') {
      patchDataBody.mediaPath = data.mediaPath
    }

    if (!successOrgDetails || !orgDetails?.id) {
      return
    }

    const patchData = {
      id: orgDetails.id,
      body: patchDataBody,
    }

    await patchOrganization(patchData)
      .unwrap()
      .then(() => {
        handleCloseData()
      })
      .catch(() => {})
  }

  const newOrgText = t('CREATE_NEW_ITEM', { item: t('ORGANIZATION') })
  const editOrgText = t('EDIT_ITEM', { item: t('ORGANIZATION') })

  const errorMessage = useErrorMessage()

  return (
    <>
      <PageTitle title={t('ORGANIZATIONS')} />

      <RoundButton sx={{ mb: 2 }} onClick={handleOpenNewData}>
        {newOrgText}
      </RoundButton>

      {isSuccessNew && (
        <AlertMessage severity="success">
          {t('CREATE_NEW_ITEM_SUCCESS', { item: t('ORGANIZATION') })}
        </AlertMessage>
      )}

      {isSuccessEdit && (
        <AlertMessage severity="success">
          {t('EDIT_ITEM_SUCCESS', { item: t('ORGANIZATION') })}
        </AlertMessage>
      )}

      {isSuccessDelete && (
        <AlertMessage severity="success">
          {t('DELETE_ITEM_SUCCESS', { item: t('ORGANIZATION') })}
        </AlertMessage>
      )}

      {isErrorDelete && (
        <AlertMessage severity="error">
          {errorMessage('create', 'ORGANIZATION', errorDelete)}
        </AlertMessage>
      )}

      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        rows={listData || []}
        columns={columns}
        initialState={initialState}
        pageSizeOptions={pageSizeOptions}
        loading={isLoadingNew || isLoadingEdit || isLoadingDelete || isFetching}
        slots={slots}
        localeText={localeText}
        sx={{ backgroundColor: 'white', boxShadow: 3 }}
      />
      <NewEditData
        title={dataType === 'create' ? newOrgText : editOrgText}
        onSubmit={dataType === 'create' ? onSubmit : onEditSubmit}
        handleSubmit={handleSubmit}
        isOpen={openData}
        onClose={handleCloseData}
        confirmText={dataType === 'create' ? newOrgText : editOrgText}
        isLoading={isLoading}
      >
        <>
          {isErrorNew && (
            <AlertMessage severity="error">
              {errorMessage('create', 'ORGANIZATION', errorNew)}
            </AlertMessage>
          )}

          {isErrorEdit && (
            <AlertMessage severity="error">
              {errorMessage('edit', 'ORGANIZATION', errorEdit)}
            </AlertMessage>
          )}

          <FormTextField
            name="organizationCode"
            label={t('ORGANIZATION_CODE')}
            disabled={dataType === 'edit'}
            control={control}
            error={errors.organizationCode}
          />
          <FormTextField
            name="organizationName.en"
            label={t('ORGANIZATION_NAME_LOCALE', { locale: t('EN') })}
            control={control}
            error={errors.organizationName?.en}
          />
          <FormTextField
            name="organizationName.zhHk"
            label={t('ORGANIZATION_NAME_LOCALE', { locale: t('ZH_HK') })}
            control={control}
            error={errors.organizationName?.zhHk}
          />
          <FormTextField
            name="organizationName.zhCn"
            label={t('ORGANIZATION_NAME_LOCALE', { locale: t('ZH_CN') })}
            control={control}
            error={errors.organizationName?.zhCn}
          />
          <FormTextField
            name="apiKey"
            label={t('API_KEY')}
            control={control}
            error={errors.apiKey}
          />
          <FormPullDown
            name="media"
            label={t('MEDIA')}
            control={control}
            error={errors.media}
            items={[
              { value: 'none', label: t('NONE') },
              { value: 'cloud', label: t('CLOUD') },
              { value: 'local', label: t('LOCAL') },
            ]}
          />
          {watch('media') === 'local' && (
            <>
              <FormTextField
                name="mediaPath"
                label={t('MEDIA_PATH')}
                control={control}
                error={errors.mediaPath}
              />
            </>
          )}
          <FormPullDown
            name="login"
            label={t('LOGIN_TYPE')}
            control={control}
            error={errors.login}
            items={[
              { value: 'user', label: t('USER_LOGIN') },
              { value: 'ip', label: t('IP_LOGIN') },
            ]}
          />
          <FormTextField
            name="whitelist"
            label={t('WHITELIST')}
            disabled={watch('login') === 'user'}
            control={control}
            error={errors.whitelist}
          />
          <FormSwitch name="ntag" label={t('NTAG_ENABLED')} control={control} />
        </>
      </NewEditData>
      <ConfigNtag
        isOpen={openConfigNtag}
        setIsOpen={setOpenConfigNtag}
        details={orgDetails}
      />
    </>
  )
}
