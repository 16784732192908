import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import {
  Avatar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListProps,
  Divider,
  Box,
  IconButton,
} from '@mui/material'
import { mainMenu, systemAdminMenu, managerMenu } from './MenuItems'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import CenterBox from '../styles/CenterBox.styled'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from '../redux/configureStore'
import { setOpenDrawer } from '../redux/userSlice'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import useUserDetails from '../hooks/useUserDetails'
import { MenuItem } from '../types/global'

const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

const MenuList = styled(List)<ListProps>(({ theme }) => ({
  '& .MuiListItemButton-root:hover': {
    borderRadius: '0px 50px 50px 0px',
  },
  '& .Mui-selected, & .Mui-selected:hover': {
    background: 'linear-gradient(to left, #25A0CE, #25CE89)',
    borderRadius: '0px 50px 50px 0px',
    boxShadow: theme.shadows[4],
    color: theme.palette.common.white,
    '&, & .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    },
  },
}))

interface DrawerListProps {
  mobile?: boolean
}

function DrawerMenuItem({
  item,
  selected,
  openDrawer,
  onClick,
}: {
  item: MenuItem
  selected: boolean
  openDrawer: boolean
  onClick: () => void
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <ListItem
      disablePadding
      sx={{ display: 'block', color: theme.palette.info.main }}
    >
      <ListItemButton
        onClick={onClick}
        selected={selected}
        sx={{
          minHeight: 48,
          justifyContent: openDrawer ? 'initial' : 'center',
          px: 2.5,
          my: 1,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: openDrawer ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={t(item.name)}
          sx={{ opacity: openDrawer ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default function DrawerList({ mobile = false }: DrawerListProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const { openDrawer } = useSelector((state: RootState) => state.user)
  const { isAdmin, isSystemAdmin, isManager } = useUserDetails()

  const handleDrawer = () => {
    dispatch(setOpenDrawer(!openDrawer))
  }

  const handleMenuClick = (path: string) => {
    navigate(path)
    if (mobile) {
      handleDrawer()
    }
  }

  return (
    <>
      <DrawerHeader>
        <ListItem
          disablePadding
          sx={{
            display: 'block',
            paddingTop: theme.spacing(2),
            color: theme.palette.info.main,
          }}
        >
          <ListItemButton
            onClick={handleDrawer}
            color="info"
            sx={{
              minHeight: 48,
              justifyContent: openDrawer ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: openDrawer ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Avatar
                src="logo.png"
                sx={{ backgroundColor: theme.palette.background.paper }}
              />
            </ListItemIcon>
            <ListItemText
              primary={t('APP_NAME_MENU')}
              sx={{ opacity: openDrawer ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <Box
          pt={1}
          pr={1}
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'flex-end',
          }}
        >
          <IconButton color="info" onClick={handleDrawer}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </DrawerHeader>
      <MenuList>
        {mainMenu.map((item, index) => (
          <DrawerMenuItem
            key={index}
            item={item}
            selected={item.path === location.pathname}
            openDrawer={openDrawer}
            onClick={() => handleMenuClick(item.path)}
          />
        ))}
        {isAdmin && (
          <>
            <CenterBox>
              <Divider sx={{ width: { xs: '100%', md: '80%' } }} />
            </CenterBox>
            {isSystemAdmin &&
              systemAdminMenu.map((item, index) => (
                <DrawerMenuItem
                  key={index}
                  item={item}
                  selected={item.path === location.pathname}
                  openDrawer={openDrawer}
                  onClick={() => handleMenuClick(item.path)}
                />
              ))}
            {isManager &&
              managerMenu.map((item, index) => (
                <DrawerMenuItem
                  key={index}
                  item={item}
                  selected={item.path === location.pathname}
                  openDrawer={openDrawer}
                  onClick={() => handleMenuClick(item.path)}
                />
              ))}
          </>
        )}
      </MenuList>
    </>
  )
}
