/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid'
import { Grid, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useLocaleName from '../hooks/useLocaleName'
import useErrorMessage from '../hooks/useErrorMessage'
import useDataGridOptions from '../hooks/useDataGridOptions'
import RoundButton from '../styles/RoundButton.styled'
import AlertMessage from '../styles/AlertMessage.styled'
import {
  useLazyGetAreasQuery,
  useLazyGetAreaDetailsQuery,
  useLazyGetFloorPlanQuery,
  usePostAreaMutation,
  usePatchAreaMutation,
  useDeleteAreaMutation,
  useUploadFloorPlanMutation,
  useDeleteFloorPlanMutation,
} from '../redux/areaApi'
import { useGetOrganizationsQuery } from '../redux/organizationApi'
import {
  GridEditAction,
  GridFloorPlanAction,
  GridDeleteAction,
} from '../components/GridActions'
import NewEditData from '../components/NewEditData'
import { FormTextField, FormAutoComplete } from '../components/FormController'
import SearchFilter from '../components/SearchFilter'
import PageTitle from '../components/PageTitle'
import { AreaFormItem, AreaPatchItem, AreaQueryParams } from '../types/area'
import { DataType } from '../types/global'
import { useNavigate } from 'react-router-dom'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Modal from '../components/Modal'
import CenterBox from '../styles/CenterBox.styled'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default function Areas() {
  const { t } = useTranslation()
  const { dataGridProps } = useDataGridOptions()
  const navigate = useNavigate()

  const getName = useLocaleName()

  const [openData, setOpenData] = useState(false)
  const [dataType, setDataType] = useState<DataType>('create')
  const [isLoading, setIsLoading] = useState(false)
  const [openFloorPlan, setOpenFloorPlan] = useState(false)
  const [areaId, setAreaId] = useState('')
  const [currentFile, setCurrentFile] = useState<File | null>(null)

  useEffect(() => {
    triggerGetAreas({})
  }, [])

  // Close the form dialog
  const handleCloseData = () => {
    setIsLoading(false)
    setOpenData(false)
  }

  useEffect(() => {
    if (!openData) {
      resetForm()
    }
  }, [openData])

  // Open the form dialog
  const handleOpenData = () => {
    setIsLoading(false)
    setOpenData(true)
  }

  const handleOpenNewData = () => {
    setDataType('create')
    handleOpenData()
  }

  const handleEditData = () => {
    setDataType('edit')
    handleOpenData()
  }

  // Get the list of organizations from the API
  const { data: organizationData } = useGetOrganizationsQuery()

  const organizationItems = organizationData?.map((item) => ({
    value: item.id,
    label: getName(item.organizationName),
  }))

  const organizationSearchItems = organizationData?.map((item) => ({
    value: item.organizationCode,
    label: getName(item.organizationName),
  }))

  // Get the list of areas from the API
  const [triggerGetAreas, { data: listData, isFetching }] =
    useLazyGetAreasQuery()

  // Get the list of areas from the API
  const [trigger] = useLazyGetAreaDetailsQuery()

  // Get the floor plan from the API
  const [
    triggerGetFloorPlan,
    { data: floorPlan, isSuccess: isSuccessFloorPlan },
  ] = useLazyGetFloorPlanQuery()

  // Create area mutation
  const [
    postArea,
    {
      isSuccess: isSuccessNew,
      isLoading: isLoadingNew,
      isError: isErrorNew,
      error: errorNew,
      reset: resetNew,
    },
  ] = usePostAreaMutation()

  // Edit area mutation
  const [
    patchArea,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = usePatchAreaMutation()

  // Delete area mutation
  const [
    deleteArea,
    {
      isSuccess: isSuccessDelete,
      isLoading: isLoadingDelete,
      isError: isErrorDelete,
      error: errorDelete,
      reset: resetDelete,
    },
  ] = useDeleteAreaMutation()

  // Upload floor plan mutation
  const [
    postUpload,
    {
      isSuccess: isSuccessNewUpload,
      isLoading: isLoadingNewUpload,
      isError: isErrorNewUpload,
      // error: errorNewUpload,
      reset: resetNewUpload,
    },
  ] = useUploadFloorPlanMutation()

  // Delete floor plan mutation
  const [
    deleteUpload,
    {
      isSuccess: isSuccessDeleteUpload,
      isLoading: isLoadingDeleteUpload,
      isError: isErrorDeleteUpload,
      // error: errorDeleteUpload,
      reset: resetDeleteUpload,
    },
  ] = useDeleteFloorPlanMutation()

  // Reset the mutation states
  const resetAll = () => {
    setIsLoading(true)
    resetNew()
    resetEdit()
    resetDelete()
    resetNewUpload()
    resetDeleteUpload()
  }

  // Schema of the login form
  const schema = yup.object().shape({
    id: yup.string().optional(),
    areaCode: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('AREA_CODE') })),
    areaName: yup.object().shape({
      en: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('AREA_NAME_LOCALE', { locale: t('EN') }),
        }),
      ),
      zhHk: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('AREA_NAME_LOCALE', { locale: t('ZH_HK') }),
        }),
      ),
      zhCn: yup.string().required(
        t('ERROR_INPUT_REQUIRED', {
          field: t('AREA_NAME_LOCALE', { locale: t('ZH_CN') }),
        }),
      ),
    }),
    organizationId: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION') })),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm<AreaFormItem>({
    defaultValues: {
      id: '',
      areaCode: '',
      areaName: {
        en: '',
        zhHk: '',
        zhCn: '',
      },
      organizationId: '',
    },
    resolver: yupResolver(schema), // use yup to validate form values
  })

  // Schema of the form
  const searchSchema = yup.object().shape({
    code: yup
      .string()
      .test(
        'minLength',
        t('ERROR_INPUT_MIN_LENGTH', { field: t('AREA_CODE'), min: 2 }),
        (val) => {
          return !val || val.length >= 2
        },
      ),
    name: yup
      .string()
      .test(
        'minLength',
        t('ERROR_INPUT_MIN_LENGTH', { field: t('AREA_NAME'), min: 2 }),
        (val) => {
          return !val || val.length >= 2
        },
      ),
    organization: yup.string().optional().nullable(),
  })

  // Search filter React hook form setup
  const {
    handleSubmit: handleSubmitSearch,
    control: controlSearch,
    formState: { errors: errorsSearch },
    watch: watchSearch,
    reset: resetSearch,
  } = useForm<AreaQueryParams>({
    defaultValues: {
      code: '',
      name: '',
      organization: '',
    },
    resolver: yupResolver(searchSchema), // use yup to validate form values
  })

  // Form submit search data handler
  const onSubmitSearch: SubmitHandler<AreaQueryParams> = async (data) => {
    // Remove the empty fields
    const filteredData: AreaQueryParams = Object.fromEntries(
      Object.entries(data).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) => value !== '' && value !== null,
      ),
    ) as AreaQueryParams
    await triggerGetAreas(filteredData)
  }

  const resetSearchFilter = async () => {
    resetSearch()
    await triggerGetAreas({})
  }

  // Define the columns of the data grid
  const columns: GridColDef[] = [
    {
      field: 'areaCode',
      headerName: t('AREA_CODE'),
      flex: 2,
    },
    {
      field: 'areaName',
      headerName: t('AREA_NAME'),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return <>{getName(params.row.areaName)}</>
      },
    },
    {
      field: 'organizationName',
      headerName: t('ORGANIZATION_NAME'),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return <>{getName(params.row.organizationName)}</>
      },
    },
    {
      field: 'floorPlan',
      headerName: t('FLOOR_PLAN'),
      flex: 2,
      sortable: false,
      valueFormatter: (params) => {
        if (params.value) {
          return t('ENABLED')
        } else {
          return t('DISABLED')
        }
      },
    },
    {
      field: 'actions',
      headerName: t('ACTIONS'),
      flex: 1,
      type: 'actions',
      getActions: (params) => {
        const onEdit = () => {
          resetAll()
          trigger(params.id.toString(), false)
            .unwrap()
            .then((data) => {
              setValue('id', data.id)
              setValue('areaCode', data.areaCode)
              setValue('areaName.en', data.areaName.en)
              setValue('areaName.zhHk', data.areaName.zhHk)
              setValue('areaName.zhCn', data.areaName.zhCn)
              setValue('organizationId', data.organizationId)
              handleEditData()
            })
            .catch(() => {})
        }
        const onFloorPlan = async () => {
          setAreaId(params.id.toString())
          if (params.row.floorPlan) {
            await triggerGetFloorPlan(params.id.toString()).unwrap()
            setOpenFloorPlan(true)
          } else {
            setOpenFloorPlan(true)
          }
        }
        const onDelete = async () => {
          resetAll()
          await deleteArea(params.id.toString())
        }
        return [
          <GridActionsCellItem
            key="location"
            icon={<RoomPreferencesIcon />}
            label={t('LOCATIONS')}
            color="primary"
            onClick={() => navigate('/locations?area=' + params.row.areaCode)}
          />,
          <GridEditAction key="edit" onEdit={onEdit} />,
          <GridFloorPlanAction
            key="floorPlan"
            onClick={() => onFloorPlan()}
            disabled={false}
          />,
          <GridDeleteAction
            key="delete"
            action={t('AREA')}
            name={getName(params.row.areaName)}
            onDelete={onDelete}
          />,
        ]
      },
    },
  ]

  // Refresh the data grid when an area is deleted
  useEffect(() => {
    if (
      isSuccessNew ||
      isSuccessEdit ||
      isSuccessDelete ||
      isSuccessNewUpload ||
      isSuccessDeleteUpload
    ) {
      const watchData = watchSearch()
      onSubmitSearch(watchData)
      setIsLoading(false)
    }
  }, [
    isSuccessNew,
    isSuccessEdit,
    isSuccessDelete,
    isSuccessNewUpload,
    isSuccessDeleteUpload,
  ])

  useEffect(() => {
    if (
      isErrorNew ||
      isErrorEdit ||
      isErrorDelete ||
      isErrorNewUpload ||
      isErrorNewUpload
    ) {
      setIsLoading(false)
    }
  }, [
    isErrorNew,
    isErrorEdit,
    isErrorDelete,
    isErrorNewUpload,
    isErrorDeleteUpload,
  ])

  const areaData = listData?.find((item) => item.id === areaId)
  const isFloorPlan = areaData?.floorPlan

  // Form submit data handler
  const onSubmit: SubmitHandler<AreaFormItem> = async (data) => {
    resetAll()
    if (dataType === 'create') {
      // Create new area
      const postData = { ...data }
      delete postData.id
      await postArea(postData)
        .unwrap()
        .then(() => {
          handleCloseData()
        })
        .catch(() => {})
    } else {
      // Edit area
      if (data.id !== undefined) {
        const patchData: AreaPatchItem = {
          id: data.id,
          areaName: data.areaName,
        }
        await patchArea(patchData)
          .unwrap()
          .then(() => {
            handleCloseData()
          })
          .catch(() => {})
      }
    }
  }

  const newText = t('CREATE_NEW_ITEM', { item: t('AREA') })
  const editText = t('EDIT_ITEM', { item: t('AREA') })

  const errorMessage = useErrorMessage()

  // Select a file for upload the floor plan
  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { files } = event.target
    const selectedFiles = files as FileList
    const img = new Image()
    img.src = URL.createObjectURL(selectedFiles[0])
    img.onload = () => {
      if (
        img.naturalWidth > 1000 ||
        img.naturalHeight > 1000 ||
        selectedFiles[0].size > 10 * 1024 * 1024 // file size < 10MB
      ) {
        alert(t('ERROR_IMAGE_SIZE'))
        return
      } else {
        setCurrentFile(selectedFiles[0])
      }
    }
  }

  const uploadFloorPlan = () => {
    if (currentFile) {
      resetAll()
      const formData = new FormData()
      formData.append('floorPlan', currentFile)
      postUpload({ id: areaId, body: formData })
        .unwrap()
        .then(() => {
          closeFloorPlan()
        })
        .catch(() => {})
    }
  }

  const deleteFloorPlan = () => {
    resetAll()
    deleteUpload(areaId)
      .unwrap()
      .then(() => {
        closeFloorPlan()
      })
      .catch(() => {})
  }

  const closeFloorPlan = () => {
    setOpenFloorPlan(false)
    setCurrentFile(null)
  }

  return (
    <>
      <PageTitle title={t('AREAS')} />
      <RoundButton sx={{ mb: 2 }} onClick={handleOpenNewData}>
        {newText}
      </RoundButton>

      {isSuccessNew && (
        <AlertMessage severity="success">
          {t('CREATE_NEW_ITEM_SUCCESS', { item: t('AREA') })}
        </AlertMessage>
      )}

      {isSuccessEdit && (
        <AlertMessage severity="success">
          {t('EDIT_ITEM_SUCCESS', { item: t('AREA') })}
        </AlertMessage>
      )}

      {isSuccessDelete && (
        <AlertMessage severity="success">
          {t('DELETE_ITEM_SUCCESS', { item: t('AREA') })}
        </AlertMessage>
      )}

      {isSuccessNewUpload && (
        <AlertMessage severity="success">
          {t('CREATE_NEW_ITEM_SUCCESS', { item: t('FLOOR_PLAN') })}
        </AlertMessage>
      )}

      {isSuccessDeleteUpload && (
        <AlertMessage severity="success">
          {t('DELETE_ITEM_SUCCESS', { item: t('FLOOR_PLAN') })}
        </AlertMessage>
      )}

      {isErrorDelete && (
        <AlertMessage severity="error">
          {errorMessage('delete', 'AREA', errorDelete)}
        </AlertMessage>
      )}

      {isErrorDeleteUpload && (
        <AlertMessage severity="error">
          {errorMessage('delete', 'FLOOR_PLAN', errorEdit)}
        </AlertMessage>
      )}

      <SearchFilter
        onSubmit={onSubmitSearch}
        handleSubmit={handleSubmitSearch}
        onReset={resetSearchFilter}
      >
        <Grid item xs={12} md={2}>
          <FormTextField
            name="code"
            label={t('AREA_CODE')}
            control={controlSearch}
            error={errorsSearch.code}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormTextField
            name="name"
            label={t('AREA_NAME')}
            control={controlSearch}
            error={errorsSearch.name}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormAutoComplete
            name="organization"
            label={t('ORGANIZATION')}
            control={controlSearch}
            error={errorsSearch.organization}
            items={organizationSearchItems || []}
          />
        </Grid>
      </SearchFilter>

      <DataGrid
        {...dataGridProps}
        rows={listData || []}
        columns={columns}
        loading={
          isLoadingNew ||
          isLoadingEdit ||
          isLoadingDelete ||
          isFetching ||
          isLoadingNewUpload ||
          isLoadingDeleteUpload
        }
        sx={{ backgroundColor: 'white', boxShadow: 3 }}
      />
      <NewEditData
        title={dataType === 'create' ? newText : editText}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        isOpen={openData}
        onClose={handleCloseData}
        confirmText={dataType === 'create' ? newText : editText}
        isLoading={isLoading}
      >
        <>
          {isErrorNew && (
            <AlertMessage severity="error">
              {errorMessage('create', 'AREA', errorNew)}
            </AlertMessage>
          )}

          {isErrorEdit && (
            <AlertMessage severity="error">
              {errorMessage('edit', 'AREA', errorEdit)}
            </AlertMessage>
          )}

          <FormTextField
            name="areaCode"
            label={t('AREA_CODE')}
            disabled={dataType === 'edit'}
            control={control}
            error={errors.areaCode}
          />
          <FormTextField
            name="areaName.en"
            label={t('AREA_NAME_LOCALE', { locale: t('EN') })}
            control={control}
            error={errors.areaName?.en}
          />
          <FormTextField
            name="areaName.zhHk"
            label={t('AREA_NAME_LOCALE', { locale: t('ZH_HK') })}
            control={control}
            error={errors.areaName?.zhHk}
          />
          <FormTextField
            name="areaName.zhCn"
            label={t('AREA_NAME_LOCALE', { locale: t('ZH_CN') })}
            control={control}
            error={errors.areaName?.zhCn}
          />
          <FormAutoComplete
            name="organizationId"
            label={t('ORGANIZATION')}
            disabled={dataType === 'edit'}
            control={control}
            error={errors.organizationId}
            items={organizationItems || []}
          />
        </>
      </NewEditData>
      <Modal
        size="xl"
        isOpen={openFloorPlan}
        onClose={closeFloorPlan}
        onlyClose={true}
      >
        <Box>
          {!isFloorPlan && (
            <CenterBox sx={{ flexDirection: 'column' }}>
              <Button
                variant="outlined"
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<PermMediaOutlinedIcon />}
                sx={{ mb: 2 }}
              >
                {t('SELECT_FLOOR_PLAN_FILE')}
                <VisuallyHiddenInput
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={selectFile}
                />
              </Button>
              {currentFile?.name}
              <Button
                variant="contained"
                role={undefined}
                startIcon={<CloudUploadOutlinedIcon />}
                onClick={uploadFloorPlan}
                disabled={!currentFile}
                sx={{ mt: 2 }}
              >
                {t('UPLOAD_FLOOR_PLAN')}
              </Button>
            </CenterBox>
          )}
          {isErrorNewUpload && (
            <AlertMessage severity="error">
              {errorMessage('upload', 'FLOOR_PLAN', errorEdit)}
            </AlertMessage>
          )}
          {isFloorPlan && isSuccessFloorPlan && (
            <CenterBox sx={{ flexDirection: 'column' }}>
              <img
                id="floor-plan-image"
                src={floorPlan?.url}
                alt="Floor plan"
              />

              <Button
                variant="contained"
                color="error"
                role={undefined}
                startIcon={<DeleteOutlinedIcon />}
                sx={{ mt: 2 }}
                onClick={deleteFloorPlan}
              >
                {t('DELETE_ITEM', { item: t('FLOOR_PLAN') })}
              </Button>
            </CenterBox>
          )}
        </Box>
      </Modal>
    </>
  )
}
