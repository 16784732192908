import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function HelmetComponent({
  title,
  description,
  noTitle = false,
  inline = false,
}: {
  title?: string
  description?: string
  noTitle?: boolean
  inline?: boolean
}): JSX.Element {
  const { t } = useTranslation()

  const appName = t('APP_NAME')
  const appDescription = t('APP_DESCRIPTION')

  let pageTitle = appName

  if (title) {
    pageTitle = `${title} | ${appName}`
  }

  let pageDesc = appDescription

  if (description) {
    pageDesc = description
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDesc} />
      </Helmet>
      {!noTitle && title && (
        <Box
          sx={{
            display: inline ? 'inline-block' : 'block',
            mr: inline ? 2 : 0,
          }}
        >
          <Typography variant="h1">{title}</Typography>
        </Box>
      )}
    </>
  )
}
