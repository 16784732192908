/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler, FieldError } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Snackbar, Alert } from '@mui/material'

import {
  usePostNtagCreateMutation,
  useLazyGetNtagDetailsQuery,
  usePatchNtagMutation,
} from '../../redux/ntagApi'
import { useGetOrganizationsQuery } from '../../redux/organizationApi'

import AlertMessage from '../../styles/AlertMessage.styled'
import NewEditData from '../../components/NewEditData'
import useLocaleName from '../../hooks/useLocaleName'
import useLocationsFilter from '../../hooks/useLocationsFilter'
import useErrorMessage from '../../hooks/useErrorMessage'

import {
  FormTextField,
  FormAutoComplete,
  FormSwitch,
} from '../../components/FormController'

import type { NtagCreateRequest, NtagPatchRequest } from '../../types/ntag'
import type { DataType, OptionItems } from '../../types/global'
import type { OrganizationsList } from '../../types/organization'

interface CreateNtagProps {
  openData: boolean
  setOpenData: React.Dispatch<React.SetStateAction<boolean>>
  dataType: DataType
  editData?: string
}

export default function CreateNtag({
  openData,
  setOpenData,
  dataType,
  editData,
}: CreateNtagProps) {
  const { t } = useTranslation()
  const getName = useLocaleName()
  const errorMessage = useErrorMessage()

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const currentOrg = useRef<string>('')

  const { data: organizationData, isSuccess: successOrganizations } =
    useGetOrganizationsQuery()

  const [getNtagDetails] = useLazyGetNtagDetailsQuery()

  const [
    postNtagCreate,
    { isLoading, isSuccess, isError: isErrorNew, error: errorNew },
  ] = usePostNtagCreateMutation()

  const [
    patchNtag,
    { isSuccess: isSuccessEdit, isError: isErrorEdit, error: errorEdit },
  ] = usePatchNtagMutation()

  const { locations, areas, getLocationsFilter } = useLocationsFilter('id')

  const getOrganization = (format: string): OptionItems | [] => {
    const data = organizationData?.map((item) => ({
      value: format === 'code' ? item.organizationCode : item.id,
      label: getName(item.organizationName),
    }))
    return data ? data : []
  }

  const orgIdItems = getOrganization('id')

  const schema = yup.object().shape({
    uid: yup
      .string()
      .matches(
        /^[0-9A-Fa-f]{14,14}$/,
        t('ERROR_INPUT_FORMAT', { field: t('NTAG_UID') }),
      )
      .required(t('ERROR_INPUT_REQUIRED', { field: t('NTAG_UID') })),
    ntagCode: yup
      .string()
      .trim()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('NTAG_CODE') })),
    organizationId: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('ORGANIZATION') })),
    locationIds: yup.array(yup.string().required()).max(128),
    action: yup.string().required(),
    enabled: yup
      .boolean()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('ENABLED') })),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset: resetForm,
  } = useForm<NtagCreateRequest>({
    defaultValues: {
      uid: '',
      ntagCode: '',
      organizationId: '',
      locationIds: [],
      action: 'reset',
      enabled: true,
    },
    resolver: yupResolver(schema), // use yup to validate form values
  })

  const onSubmit: SubmitHandler<NtagCreateRequest> = async (data) => {
    try {
      await postNtagCreate(data).unwrap()
      setOpenSnackbar(true)
    } catch (_) {
      /* empty */
    }
  }

  const onPatchSubmit: SubmitHandler<NtagCreateRequest> = async (data) => {
    try {
      const patchData: NtagPatchRequest = {
        id: editData as string,
        body: {
          locationIds: data.locationIds,
          enabled: data.enabled,
        },
      }
      await patchNtag(patchData).unwrap()
      setOpenSnackbar(true)
    } catch (_) {
      /* empty */
    }
  }

  const handleCloseData = () => {
    setOpenData(false)
    //resetForm()
    //resetLocationsFilter()
  }

  const newText = t('CREATE_NEW_ITEM', { item: t('NTAG') })
  const editText = t('EDIT_ITEM', { item: t('NTAG') })
  const orgField = watch('organizationId')

  const getOrgCode = (id: string, orgData: OrganizationsList) => {
    let orgCode = ''
    for (const org of orgData) {
      if (org.id === id) {
        orgCode = org.organizationCode
        break
      }
    }
    return orgCode
  }

  // set the location autocomplete items based on the selected organization
  useEffect(() => {
    if (successOrganizations && openData) {
      const orgCode = getOrgCode(orgField, organizationData)
      if (orgCode) {
        getLocationsFilter(orgCode)
      }
    }
  }, [orgField, openData])

  // get the details of the ntag to be edited
  const getDetails = async (id: string) => {
    try {
      const data = await getNtagDetails(id).unwrap()
      if (data) {
        setValue('uid', data.uid)
        setValue('ntagCode', data.ntagCode)
        setValue('organizationId', data.organizationId)
        setValue('locationIds', data.locationIds)
        setValue('enabled', data.enabled)
        currentOrg.current = data.organizationId
      }
    } catch (_) {
      /* empty */
    }
  }

  useEffect(() => {
    if (openData) {
      resetForm()
    }
    if (dataType === 'edit' && editData && openData) {
      getDetails(editData)
    }
  }, [dataType, editData, openData])

  // display the success message when a new item is created
  useEffect(() => {
    if (isSuccess || isSuccessEdit) {
      setOpenSnackbar(true)
      handleCloseData()
    }
  }, [isSuccess, isSuccessEdit])

  return (
    <>
      <NewEditData
        title={dataType === 'create' ? newText : editText}
        onSubmit={dataType === 'create' ? onSubmit : onPatchSubmit}
        handleSubmit={handleSubmit}
        isOpen={openData}
        onClose={handleCloseData}
        confirmText={dataType === 'create' ? newText : editText}
        isLoading={isLoading}
      >
        <>
          {isErrorNew && (
            <AlertMessage severity="error">
              {errorMessage('create', 'NTAG', errorNew)}
            </AlertMessage>
          )}

          {isErrorEdit && (
            <AlertMessage severity="error">
              {errorMessage('edit', 'USER', errorEdit)}
            </AlertMessage>
          )}

          <FormTextField
            name="ntagCode"
            label={t('NTAG_CODE')}
            control={control}
            error={errors.ntagCode}
            disabled={dataType === 'edit'}
          />
          <FormTextField
            name="uid"
            label={t('NTAG_UID')}
            control={control}
            error={errors.uid}
            disabled={dataType === 'edit'}
          />

          <FormAutoComplete
            name="organizationId"
            label={t('ORGANIZATION')}
            disabled={dataType === 'edit'}
            control={control}
            error={errors.organizationId}
            items={orgIdItems}
          />

          <FormAutoComplete
            name="locationIds"
            label={t('LOCATIONS')}
            disabled={orgField === '' || locations.length === 0}
            control={control}
            error={errors.locationIds as FieldError}
            groupLabel={areas}
            items={locations.length > 0 ? locations : []}
            multiple
          />

          <FormSwitch name="enabled" label={t('ENABLED')} control={control} />
        </>
      </NewEditData>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={null}
        transitionDuration={0}
      >
        <Alert severity="success" onClose={() => setOpenSnackbar(false)}>
          {t(
            dataType === 'create'
              ? 'CREATE_NEW_ITEM_SUCCESS'
              : 'EDIT_ITEM_SUCCESS',
            { item: t('NTAG') },
          )}
        </Alert>
      </Snackbar>
    </>
  )
}
