import React from 'react'
import {
  TextField,
  Button,
  Typography,
  Alert,
  Box,
  BoxProps,
} from '@mui/material'
import CenterBox from '../styles/CenterBox.styled'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../components/LanguageSwitcher'
import PageTitle from '../components/PageTitle'
import { usePostLoginMutation } from '../redux/authApi'
import { UserLogin } from '../types/user'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, AppDispatch } from '../redux/configureStore'
import { userCookieExpired } from '../redux/userSlice'
import { styled } from '@mui/system'
import LandingLayout from '../layouts/LandingLayout'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

export const FormBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '84%',
  width: '84%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px',
})) as typeof Box

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { cookieExpired } = useSelector((state: RootState) => state.user)

  // Schema of the login form
  const schema = yup.object().shape({
    username: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('USERNAME') })),
    password: yup
      .string()
      .required(t('ERROR_INPUT_REQUIRED', { field: t('PASSWORD') })),
  })

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<UserLogin>({
    resolver: yupResolver(schema), // use yup to validate form values
  })

  const [postLogin, { isLoading, isError, error }] = usePostLoginMutation()

  // Form submit handler
  const onSubmit: SubmitHandler<UserLogin> = async (data) => {
    dispatch(userCookieExpired(false))
    await postLogin(data)
      .unwrap()
      .then(() => {
        reset()
        navigate('/dashboard')
      })
      .catch(() => {})
  }

  return (
    <LandingLayout>
      <PageTitle noTitle={true} />
      <FormBox component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box pt={1} pr={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LanguageSwitcher />
        </Box>
        <CenterBox
          sx={{
            height: '100%',
          }}
        >
          <CenterBox
            sx={{
              flexDirection: 'column',
              width: { xs: '90%', md: '80%', lg: '70%' },
              gap: 2,
            }}
          >
            <img
              src="logo.png"
              style={{
                objectFit: 'contain',
                width: '120px',
                height: '120px',
              }}
            />
            <Typography variant="h4">{t('LOGIN')}</Typography>
            {isError && (error as FetchBaseQueryError).status === 401 && (
              <Alert severity="error">{t('ERROR_LOGIN')}</Alert>
            )}
            {isError && (error as FetchBaseQueryError).status === 429 && (
              <Alert severity="error">
                {t('ERROR_LOGIN_TOO_MANY_REQUESTS')}
              </Alert>
            )}
            {isError &&
              (error as FetchBaseQueryError).status !== 401 &&
              (error as FetchBaseQueryError).status !== 429 && (
                <Alert severity="error">{t('ERROR_SERVER_CONNECTION')}</Alert>
              )}
            {cookieExpired && (
              <Alert severity="error">{t('ERROR_COOKIE_EXPIRED')}</Alert>
            )}
            <Controller
              name="username"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  label={t('USERNAME')}
                  {...field}
                  error={!!errors.username}
                  helperText={errors.username?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="password"
                  label={t('PASSWORD')}
                  {...field}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
            <Button type="submit" disabled={isLoading}>
              {!isLoading ? t('LOGIN') : t('LOADING')}
            </Button>
          </CenterBox>
        </CenterBox>
      </FormBox>
    </LandingLayout>
  )
}
