import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage, DataType } from '../types/global'

export default function useErrorMessage() {
  const { t } = useTranslation()
  return (
    dataType: DataType | 'delete' | 'upload' | 'ntagVerify',
    item: string,
    error: FetchBaseQueryError | SerializedError | undefined,
  ) => {
    if (error) {
      let message = ''
      let errorCode = 0
      if ('status' in error && 'data' in error) {
        const errorMessage = error.data as ErrorMessage
        if (errorMessage && errorMessage.error) {
          message = t(errorMessage.error)
        } else {
          message = t('ERROR_UNKNOWN')
        }

        if (errorMessage.errorCode) {
          errorCode = errorMessage.errorCode
        }
      } else {
        message = t('ERROR_UNKNOWN')
      }
      if (dataType === 'create') {
        return t('ERROR_CREATE_NEW_ITEM', { item: t(item), message })
      } else if (dataType === 'edit') {
        return t('ERROR_EDIT_ITEM', { item: t(item), message })
      } else if (dataType === 'delete') {
        return t('ERROR_DELETE_ITEM', { item: t(item), message })
      } else if (dataType === 'upload') {
        return t('ERROR_UPLOAD_FLOOR_PLAN', { message })
      } else if (dataType === 'ntagVerify') {
        return t('ERROR_INVALID_NTAG', { errorCode })
      } else {
        return t('ERROR_UNKNOWN')
      }
    } else {
      return undefined
    }
  }
}
