/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect } from 'react'
import { QrReader, OnResultFunction } from 'react-qr-reader'
import { useTranslation } from 'react-i18next'
import { BrowserView, MobileView } from 'react-device-detect'
import { Typography } from '@mui/material'
import { useResetSignalMutation } from '../redux/locationApi'
import Modal from '../components/Modal'
import PageTitle from '../components/PageTitle'
import { useLazyGetDashboardQuery } from '../redux/healthApi'

export default function QrScanner() {
  const { t } = useTranslation()
  const [qr, setQr] = useState('')
  const [resetRes, setResetRes] = useState<number | null>(null)

  const [healthCheck] = useLazyGetDashboardQuery()

  // Trigger the dashboard health check
  useEffect(() => {
    healthCheck()
  }, [])

  const [
    resetSignal,
    { data: dataReset, isSuccess: isSuccessReset, isError: isErrorReset },
  ] = useResetSignalMutation()

  const onResult: OnResultFunction = (result) => {
    if (!!result) {
      const token = result.getText()
      setQr(token)
    }
  }

  const closeModal = () => {
    setResetRes(null)
    setQr('')
  }

  useEffect(() => {
    if (qr !== '') {
      resetSignal({ token: qr })
    }
  }, [qr])

  useEffect(() => {
    if (
      dataReset &&
      isSuccessReset &&
      typeof dataReset.resetSignals === 'number'
    ) {
      setResetRes(dataReset.resetSignals)
    }

    if (isErrorReset) {
      setResetRes(-1)
    }
  }, [dataReset, isSuccessReset, isErrorReset])

  return (
    <>
      <PageTitle title={t('RESET_SIGNAL')} />
      <MobileView>
        {qr === '' && (
          <QrReader
            onResult={onResult}
            constraints={{ facingMode: 'environment' }}
            containerStyle={{ width: '100%', padding: 'none', margin: 'none' }}
            videoContainerStyle={{ padding: 'none', margin: 'none' }}
            videoStyle={{ padding: 'none', margin: 'none' }}
          />
        )}
        <Modal
          isOpen={resetRes !== null}
          onClose={closeModal}
          onlyClose={true}
          content={
            resetRes !== null && resetRes >= 0
              ? t('RESET_LOCATION_SIGNAL', { num: resetRes })
              : resetRes !== null && resetRes <= 0
                ? t('ERROR_RESET_LOCATION_SIGNAL')
                : ''
          }
        />
      </MobileView>
      <BrowserView>
        <Typography variant="body1">
          {t('RESET_LOCATION_SIGNAL_MOBILE_ONLY')}
        </Typography>
      </BrowserView>
    </>
  )
}
