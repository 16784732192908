import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'

export const healthApi = createApi({
  baseQuery,
  reducerPath: 'healthApi',
  endpoints: (build) => ({
    getDashboard: build.query<void, void>({
      query: () => {
        return {
          url: '/dashboard',
          method: 'GET',
        }
      },
    }),
  }),
})

export const { useLazyGetDashboardQuery } = healthApi
