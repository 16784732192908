/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PageTitle from '../components/PageTitle'
import { connectSocket, disconnectSocket } from '../utils/socket'
import { useGetAreasQuery, useLazyGetFloorPlanQuery } from '../redux/areaApi'
import { useLazyGetLocationsQuery } from '../redux/locationApi'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import {
  Box,
  Snackbar,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Stack,
  Alert,
} from '@mui/material'
import { LocationStateList, LocationsList } from '../types/location'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import useLocaleName from '../hooks/useLocaleName'

export default function Signals() {
  const { t } = useTranslation()
  const theme = useTheme()
  const getName = useLocaleName()

  const [locationList, setLocationList] = useState<LocationsList>([])
  const [notifications, setNotifications] = useState<LocationStateList>([])
  const [connecting, setConnecting] = useState(false)
  const [areaCode, setAreaCode] = useState<string>('')
  const [areaId, setAreaId] = useState<string>('')
  const [areaIndex, setAreaIndex] = useState<string>('')
  const [areaPullDown, setAreaPullDown] = useState<boolean>(false)

  const { data: areas } = useGetAreasQuery({})
  const [triggerGetLocations, { data: locations }] = useLazyGetLocationsQuery()
  const [
    triggerGetFloorPlan,
    { data: floorPlan, isSuccess: isSuccessFloorPlan },
  ] = useLazyGetFloorPlanQuery()

  const changeSignal = (
    state: string,
    locationId: string,
    list: LocationStateList,
    event?: string,
  ) => {
    const itemIndex = list.findIndex((location) => location.id === locationId)
    if (itemIndex !== -1) {
      const newItem = { ...list[itemIndex], state, event }

      setNotifications((prev) => {
        const index = prev.findIndex((item) => item.id === locationId)
        if (index === -1) {
          // Push the new notification
          return [...prev, newItem]
        } else {
          // Update the existing notification
          return [...prev.slice(0, index), newItem, ...prev.slice(index + 1)]
        }
      })

      return [
        ...list.slice(0, itemIndex),
        newItem,
        ...list.slice(itemIndex + 1),
      ]
    }
    return list
  }

  const handleAreaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (areas) {
      const selectedArea = parseInt(event.target.value)
      setAreaIndex(event.target.value)
      setAreaCode(areas[selectedArea].areaCode)
      setAreaId(areas[selectedArea].id)
    }
  }

  const handleCloseAlert = (index: number) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index))
  }

  useEffect(() => {
    connectSocket().then((socket) => {
      socket.on('connect', () => {
        setConnecting(true)
      })

      socket.on('disconnect', () => {
        setConnecting(false)
      })

      socket.on('normal', (value) => {
        setLocationList((prev) => {
          return changeSignal('normal', value.locationId, prev)
        })

        // Wait 5 seconds before removing the notification
        setTimeout(() => {
          setNotifications((prev) => {
            return prev.filter((item) => item.id !== value.locationId)
          })
        }, 5000)
      })

      socket.on('warning', (value) => {
        setLocationList((prev) => {
          return changeSignal('warning', value.locationId, prev, value.event)
        })
      })

      socket.on('danger', (value) => {
        setLocationList((prev) => {
          return changeSignal('danger', value.locationId, prev, value.event)
        })
      })
    })

    return () => {
      disconnectSocket()
      setConnecting(false)
    }
  }, [])

  useEffect(() => {
    triggerGetLocations({})
    if (areas && areas.length === 1) {
      setAreaCode(areas[0].areaCode)
      setAreaId(areas[0].id)
    }

    if (areas && areas.length > 0 && !areaPullDown) {
      let planExist = false
      for (const area of areas) {
        if (area.floorPlan) {
          planExist = true
          break
        }
      }
      return setAreaPullDown(planExist)
    }
  }, [areas, areaCode])

  useEffect(() => {
    if (locations) {
      const list = locations.filter((location) => {
        return (
          (location.floorPlanX !== null || location.floorPlanX !== undefined) &&
          (location.floorPlanY !== null || location.floorPlanY !== undefined)
        )
      })
      setLocationList(list)
    }
  }, [locations])

  useEffect(() => {
    if (areaId) {
      triggerGetFloorPlan(areaId)
    }
  }, [areaId])

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={12}
          md={(areas?.length as number) <= 1 ? 12 : 8}
          xl={(areas?.length as number) <= 1 ? 12 : 9}
        >
          <Box>
            <PageTitle title={t('SIGNALS')} inline={true} />
            {connecting ? (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.success.main,
                  display: 'inline-block',
                }}
              >
                {t('SIGNAL_CONNECTING')}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.error.main,
                  display: 'inline-block',
                }}
              >
                {t('SIGNAL_DISCONNECTED')}
              </Typography>
            )}
          </Box>
        </Grid>
        {areas && areas.length > 1 && areaPullDown && (
          <Grid item xs={12} md={4} xl={3}>
            <TextField
              select
              fullWidth
              label={t('AREA')}
              value={areaIndex}
              onChange={handleAreaChange}
            >
              {areas.map((area, index) => {
                if (area.floorPlan) {
                  return (
                    <MenuItem key={area.id} value={index}>
                      {getName(area.areaName)}
                    </MenuItem>
                  )
                }
              })}
            </TextField>
          </Grid>
        )}
      </Grid>
      {areaId && (
        <Box sx={{ position: 'relative' }}>
          {locationList?.map((item) => (
            <CircleTwoToneIcon
              key={item.id}
              sx={{
                position: 'absolute',
                top: (item.floorPlanY as number) - 10,
                left: (item.floorPlanX as number) - 10,
                zIndex: 10,
                display:
                  item.floorPlanY && item.floorPlanX && item.areaId === areaId
                    ? 'block'
                    : 'none',
                color:
                  item.state === 'danger'
                    ? '#FF0000'
                    : item.state === 'warning'
                      ? '#FFA500'
                      : '#00FF00',
              }}
              fontSize="small"
            />
          ))}
          {isSuccessFloorPlan && (
            <img
              id="floor-plan-image"
              src={`${floorPlan?.url}`}
              alt="Floor plan"
            />
          )}
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notifications.length > 0}
        autoHideDuration={null}
        transitionDuration={0}
      >
        <Stack sx={{ flexDirection: 'column', gap: 1 }}>
          {notifications.map((item, index) => (
            <Alert
              key={index}
              severity={
                item.state === 'danger'
                  ? 'error'
                  : item.state === 'warning'
                    ? 'warning'
                    : 'success'
              }
              onClose={() => handleCloseAlert(index)}
            >
              {`${getName(item.locationName)}${t(
                item.state.toUpperCase() + '_NOTIFICATION',
              )}${item.event ? t(item.event.toUpperCase()) : ''}`}
            </Alert>
          ))}
        </Stack>
      </Snackbar>
    </>
  )
}
