import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import {
  UsersList,
  UserPostItem,
  UserPatchItem,
  UserDetailsItem,
  UserQueryParams,
  UserChangePassword,
} from '../types/user'

export const userApi = createApi({
  baseQuery,
  reducerPath: 'userApi',
  endpoints: (build) => ({
    getUsers: build.query<UsersList, UserQueryParams>({
      query: (params) => ({
        url: '/users',
        method: 'GET',
        params,
      }),
    }),
    getUserDetails: build.query<UserDetailsItem, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'GET',
      }),
    }),
    postUser: build.mutation<UserDetailsItem, UserPostItem>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    putUser: build.mutation<UserDetailsItem, UserChangePassword>({
      query: (body) => {
        return {
          url: '/users/password',
          method: 'PUT',
          body,
        }
      },
    }),
    patchUser: build.mutation<UserDetailsItem, UserPatchItem>({
      query: (data) => {
        const { id, ...body } = data
        return {
          url: `/users/${id}`,
          method: 'PATCH',
          body,
        }
      },
    }),
    deleteUser: build.mutation<void, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetUserDetailsQuery,
  usePostUserMutation,
  usePutUserMutation,
  usePatchUserMutation,
  useDeleteUserMutation,
} = userApi
