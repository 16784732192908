import { useSelector } from 'react-redux'
import { RootState } from '../redux/configureStore'

export default function useUserDetails() {
  const { userRole } = useSelector((state: RootState) => state.user)
  return {
    userRole,
    isSystemAdmin: userRole === 'system',
    isAdmin: userRole === 'system' || userRole === 'manager',
    isManager: userRole === 'manager',
    isUser: userRole === 'user',
  }
}
