import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authApi } from './authApi'
import { UserRoles } from '../types/user'

const initialState = {
  username: null as string | null,
  userRole: null as UserRoles | null,
  organizationCode: null as string | null,
  cookieExpired: false as boolean,
  openDrawer: false as boolean,
}

export const userSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    userLogout: (state) => {
      state.username = null
      state.userRole = null
      state.organizationCode = null
    },
    userCookieExpired: (state, action: PayloadAction<boolean>) => {
      state.cookieExpired = action.payload
    },
    setOpenDrawer: (state, action: PayloadAction<boolean>) => {
      state.openDrawer = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.postLogin.matchFulfilled,
      (state, action) => {
        const { username, userRole, organizationCode } = action.payload
        state.username = username
        state.userRole = userRole
        state.organizationCode = organizationCode
        state.cookieExpired = false
      },
    )
  },
})

// Action creators are generated for each case reducer function
export const { userLogout, userCookieExpired, setOpenDrawer } =
  userSlice.actions

export default userSlice.reducer
