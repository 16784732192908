import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  // DialogContentText,
  Box,
} from '@mui/material'
import RoundButton from '../styles/RoundButton.styled'
import { useTranslation } from 'react-i18next'
import { FieldValues, UseFormReturn, SubmitHandler } from 'react-hook-form'

type FormProps<TFormValues extends FieldValues> = {
  title?: string
  children?: React.ReactNode
  isOpen: boolean
  onClose: () => void
  confirmText?: string
  isLoading?: boolean
  onSubmit: SubmitHandler<TFormValues>
  handleSubmit: UseFormReturn<TFormValues>['handleSubmit']
}

export default function NewEditData<TFormValues extends FieldValues>({
  title,
  children,
  isOpen,
  onClose,
  confirmText,
  isLoading = false,
  onSubmit,
  handleSubmit,
}: FormProps<TFormValues>) {
  const { t } = useTranslation()
  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <Box id="data-form" component="form" onSubmit={handleSubmit(onSubmit)}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {/* <DialogContentText>{content}</DialogContentText> */}
          {children && <Box sx={{ pt: 2 }}>{children}</Box>}
        </DialogContent>
        <DialogActions>
          <RoundButton onClick={onClose} color="info">
            {t('CANCEL')}
          </RoundButton>
          <RoundButton type="submit" color="primary" disabled={isLoading}>
            {confirmText}
          </RoundButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
