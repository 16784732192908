import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import logger from 'redux-logger'

import userSlice from './userSlice'
import generalSlice from './generalSlice'
import { authApi } from './authApi'
import { alertApi } from './alertApi'
import { areaApi } from './areaApi'
import { ntagApi } from './ntagApi'
import { notificationApi } from './notificationApi'
import { locationApi } from './locationApi'
import { organizationApi } from './organizationApi'
import { userApi } from './userApi'
import { healthApi } from './healthApi'

import queryError from './queryError'

const reducers = combineReducers({
  user: userSlice,
  general: generalSlice,
  [authApi.reducerPath]: authApi.reducer,
  [alertApi.reducerPath]: alertApi.reducer,
  [areaApi.reducerPath]: areaApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,
  [ntagApi.reducerPath]: ntagApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [healthApi.reducerPath]: healthApi.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  whitelist: ['user'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const serializableCheck = {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
    const middleware = getDefaultMiddleware({ serializableCheck }).concat(
      alertApi.middleware,
      areaApi.middleware,
      authApi.middleware,
      locationApi.middleware,
      ntagApi.middleware,
      notificationApi.middleware,
      organizationApi.middleware,
      userApi.middleware,
      healthApi.middleware,
      queryError,
    )
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return middleware.concat(logger as any)
    }
    return middleware
  },
  devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
