import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import {
  AlertItems,
  AlertQueryParams,
  AlertPatchItems,
  StatisticsItems,
  StatisticsQueryParams,
  LogsItems,
  LogsQueryParams,
} from '../types/alert'
import queryString from 'query-string'

export const alertApi = createApi({
  baseQuery,
  reducerPath: 'alertApi',
  endpoints: (build) => ({
    getAlerts: build.query<AlertItems, AlertQueryParams>({
      query: (params) => {
        // Convert params to query string
        const queryParams = queryString.stringify(params)
        return {
          url: params ? '/alerts?' + queryParams : '/alerts',
          method: 'GET',
        }
      },
    }),
    patchAlerts: build.mutation<void, AlertPatchItems>({
      query: (body) => ({
        url: '/alerts',
        method: 'PATCH',
        body,
      }),
    }),
    getStatistics: build.query<StatisticsItems, StatisticsQueryParams>({
      query: (params) => ({
        url: '/statistics',
        method: 'GET',
        params,
      }),
    }),
    getLogs: build.query<LogsItems, LogsQueryParams>({
      query: (params) => ({
        url: '/logs',
        method: 'GET',
        params,
      }),
    }),
  }),
})

export const {
  useGetAlertsQuery,
  useLazyGetAlertsQuery,
  usePatchAlertsMutation,
  useLazyGetStatisticsQuery,
  useLazyGetLogsQuery,
} = alertApi
