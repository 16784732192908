import { createApi } from '@reduxjs/toolkit/query/react'
import baseQuery from './baseQuery'
import { SubscribeNotificationRequest } from '../types/notification'

export const notificationApi = createApi({
  baseQuery,
  reducerPath: 'notificationApi',
  endpoints: (build) => ({
    notificationSubscription: build.mutation<
      void,
      SubscribeNotificationRequest
    >({
      query: (body) => ({
        url: '/notifications',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useNotificationSubscriptionMutation } = notificationApi
