import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../components/PageTitle'
import RoundButton from '../styles/RoundButton.styled'
import { useNotificationSubscriptionMutation } from '../redux/notificationApi'
import useUserDetails from '../hooks/useUserDetails'
import { Box, Typography } from '@mui/material'
import Modal from '../components/Modal'

export default function Notifications() {
  const { t, i18n } = useTranslation()
  const language = i18n.language
  const { isSystemAdmin } = useUserDetails()

  const [pushState, setPushState] = useState('')
  const [allowPush, setAllowPush] = useState(false)
  const [notificationModal, setNotificationModal] = useState(false)
  const [modalContent, setModalContent] = useState('')

  const [notificationSubscription, { isSuccess }] =
    useNotificationSubscriptionMutation()

  const checkServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      const swReg = await navigator.serviceWorker.ready
      const pushManager = swReg.pushManager

      if (!pushManager) {
        return
      }

      setAllowPush(true)

      const permissionState = await pushManager.permissionState({
        userVisibleOnly: true,
      })

      switch (permissionState) {
        case 'prompt':
          setPushState('prompt')
          break
        case 'denied':
          setPushState('denied')
          break
        case 'granted':
          setPushState('granted')
          break
      }
    }
  }

  useEffect(() => {
    checkServiceWorker()
  }, [])

  const subscribe = async () => {
    try {
      if ('serviceWorker' in navigator) {
        const serviceWorker = await navigator.serviceWorker.ready

        if (!serviceWorker.pushManager) {
          setNotificationModal(true)
          setModalContent('NOTIFICATION_DENIED_PUSH')
          return
        }

        const subscriptionOptions = {
          userVisibleOnly: true,
          applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
        }

        const subscription =
          await serviceWorker.pushManager.subscribe(subscriptionOptions)

        const requestBody = {
          language,
          subscription: subscription.toJSON(),
        }

        await notificationSubscription(requestBody).unwrap()

        setModalContent('NOTIFICATION_SUBSCRIBED')
        setNotificationModal(true)
      }

      setPushState('granted')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if ('status' in err && err.status === 409) {
        setModalContent('ERROR_SUBSCRIPTION_EXISTS')
      } else {
        setModalContent('NOTIFICATION_DENIED_PUSH')
      }
      setNotificationModal(true)
    }
  }

  return (
    <>
      <PageTitle title={t('NOTIFICATIONS')} />
      <RoundButton
        variant="contained"
        color="primary"
        onClick={subscribe}
        disabled={pushState === 'denied' || isSuccess || isSystemAdmin}
      >
        {t('NOTIFICATION_SUBSCRIPTION')}
      </RoundButton>
      <Box mt={4}>
        <Typography variant="h3">{t('NOTIFICATION_REMARKS')}</Typography>
        {!allowPush && (
          <Typography variant="h6">
            {t('NOTIFICATION_DISALLOW_PUSH')}
          </Typography>
        )}
        {allowPush && pushState === 'denied' && (
          <Typography variant="h6">{t('NOTIFICATION_DENIED_PUSH')}</Typography>
        )}
        {allowPush && pushState === 'prompt' && (
          <Typography variant="h6">{t('NOTIFICATION_PROMPT_PUSH')}</Typography>
        )}
        {allowPush && pushState === 'granted' && (
          <Typography variant="h6">{t('NOTIFICATION_SUBSCRIBED')}</Typography>
        )}
      </Box>
      <Modal
        isOpen={notificationModal}
        onClose={() => setNotificationModal(false)}
        content={t(modalContent)}
        onlyClose
      />
    </>
  )
}
