import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL + '/api',
  credentials: 'include',
  validateStatus: (res) => {
    // If the status is 401, it considers the response to be 'unhealthy' and logout
    if (res.status === 401) {
      if (
        process.env.REACT_APP_BASENAME &&
        process.env.REACT_APP_BASENAME !== ''
      ) {
        window.location.href = process.env.REACT_APP_BASENAME + '/logout'
      } else {
        window.location.href = '/logout'
      }
      return false
    }
    return res.ok
  },
})

export default baseQuery
